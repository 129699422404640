import React, {Component} from 'react';
import {NavLink, withRouter} from 'react-router-dom';

import IntlMessages from 'util/IntlMessages';
import CustomScrollbars from 'util/CustomScrollbars';
import './SideNav.css'

class SidenavContent extends Component {

    constructor(props) {
        super(props);

        const privileges =  JSON.parse(localStorage.getItem('privileges'));
        this.state = {
            menuAgenda: privileges.P_AGENDA >= 'R' ? true : false,
            menuSettings: privileges.FLAG_ADMIN == 'Y' || privileges.P_PORTAFOGLIO >= 'R' ? true : false,
            menuEstimates: privileges.P_RPT_STIME >= 'R' ? true : false,
            menuPipeline: privileges.P_PIPELINE_FORECAST >= 'R' ? true : false,
            menuDeletedForecast: privileges.FLAG_ADMIN === 'Y',
            menuTracker: privileges.P_TRACKER_AVAILAB >= 'R',
            menuTrackerPillar: privileges.P_TRACKER_PILLAR >= 'R',
            menuAdmin: privileges.FLAG_ADMIN === 'Y',
            menuSalesOrganization: privileges.P_PORTAFOGLIO >= 'R' ? true : false,
            menuPipelineLinear: privileges.P_PIPELINE_LINEAR == 'Y',
            menuPipelineDigital: privileges.P_PIPELINE_DIGITAL == 'Y',
            menuOverbooking: privileges.P_OVERBOOKING >= 'R',
            menuOverbookingBacino: privileges.P_OVERBOOKING_BACINO >= 'R',
            menuRoobeek: privileges.P_ROOBEEK  >= 'R' ? true : false,
            menuCommissionsManagement: privileges.P_PROVV_COMMISSIONS  >= 'R' ? true : false,
            menuCommissionStatement: privileges.P_PROVV_STATEMENTS  >= 'R' ? true : false,
        };
    }

    componentWillReceiveProps(props) {
        const { refresh } = this.props;

        const privileges =  JSON.parse(localStorage.getItem('privileges'));
        // TODO this.setState(
        this.state = {
            menuAgenda: privileges.P_AGENDA >= 'R' ? true : false,
            menuSettings: privileges.FLAG_ADMIN == 'Y' || privileges.P_PORTAFOGLIO >= 'R' ? true : false,
            menuEstimates: privileges.P_RPT_STIME >= 'R' ? true : false,
            menuPipeline: privileges.P_PIPELINE_FORECAST >= 'R' ? true : false,
            menuDeletedForecast: privileges.FLAG_ADMIN === 'Y',
            menuTracker: privileges.P_TRACKER_AVAILAB >= 'R',
            menuTrackerPillar: privileges.P_TRACKER_PILLAR >= 'R',
            menuAdmin: privileges.FLAG_ADMIN == 'Y',
            menuSalesOrganization: privileges.P_PORTAFOGLIO >= 'R' ? true : false,
            menuPipelineLinear: privileges.P_PIPELINE_LINEAR == 'Y',
            menuPipelineDigital: privileges.P_PIPELINE_DIGITAL == 'Y',
            menuOverbooking: privileges.P_OVERBOOKING >= 'R',
            menuOverbookingBacino: privileges.P_OVERBOOKING_BACINO >= 'R',       
            menuRoobeek: privileges.P_ROOBEEK >= 'R' ? true : false,    
            menuCommissionsManagement: privileges.P_PROVV_COMMISSIONS >= 'R' ? true : false,    
            menuCommissionStatement: privileges.P_PROVV_STATEMENTS  >= 'R' ? true : false,
        };
    }

    componentDidMount() {
        const {history} = this.props;
        const that = this;
        const pathname = `#${history.location.pathname}`;// get current path

        const subMenuLi = document.querySelectorAll('.sub-menu > li');
        for (let i = 0; i < subMenuLi.length; i++) {
            subMenuLi[i].onclick = function (event) {
                event.stopPropagation();
            }
        }

        const menuLi = document.getElementsByClassName('menu');
        for (let i = 0; i < menuLi.length; i++) {
            menuLi[i].onclick = function (event) {
                for (let j = 0; j < menuLi.length; j++) {
                    const parentLi = that.closest(this, 'li');
                    if (menuLi[j] !== this && (parentLi === null || !parentLi.classList.contains('open'))) {
                        menuLi[j].classList.remove('open')
                    }
                }
                this.classList.toggle('open');
                event.stopPropagation();
            }
        }

        const activeLi = document.querySelector('a[href="' + pathname + '"]');// select current a element
        try {
            const activeNav = this.closest(activeLi, 'ul'); // select closest ul
            if (activeNav.classList.contains('sub-menu')) {
                this.closest(activeNav, 'li').classList.add('open');
            } else {
                this.closest(activeLi, 'li').classList.add('open');
            }
        } catch (error) {

        }
    }

    closest(el, selector) {
        try {
            let matchesFn;
            // find vendor prefix
            ['matches', 'webkitMatchesSelector', 'mozMatchesSelector', 'msMatchesSelector', 'oMatchesSelector'].some(function (fn) {
                if (typeof document.body[fn] == 'function') {
                    matchesFn = fn;
                    return true;
                }
                return false;
            });

            let parent;

            // traverse parents
            while (el) {
                parent = el.parentElement;
                if (parent && parent[matchesFn](selector)) {
                    return parent;
                }
                el = parent;
            }
        } catch (e) {

        }

        return null;
    }

    render() {
        const {
            menuAgenda,
            menuEstimates,
            menuPipeline,
            menuDeletedForecast,
            menuTracker,
            menuTrackerPillar,
            menuSettings,
            menuAdmin,
            menuSalesOrganization,
            menuPipelineLinear,
            menuPipelineDigital,
            menuOverbooking,
            menuOverbookingBacino,
            menuRoobeek,
            menuCommissionsManagement,
            menuCommissionStatement
        } = this.state;

        return (
            <CustomScrollbars className="scrollbar" style={{height: 'calc(100vh - 70px)',}}>    
                
                    <ul className="nav-menu">

                    <li className="nav-header">&nbsp;</li>

                    <li className={menuAgenda ? 'ui_tooltip menu' : 'ui_tooltip menu sidenav-menu-hidden'}>
                        <a role="button">
                        <i className="fa-kit fa-solid-calendar-user"></i>
                            <span className="nav-text"><IntlMessages id="sidebar.agenda.calendar"/></span>
                        </a>
                        <ul className="sub-menu">
                            <li>
                                <NavLink className="prepend-icon"
                                        to="/app/agenda/meeting-calendar"
                                        isActive={(match, location) => location.pathname.startsWith('/app/agenda/meeting-calendar')}>
                                    <span className="nav-text"><IntlMessages id="sidebar.calendar"/></span>
                                </NavLink>
                            </li>
                            {menuAdmin && (<li>
                                <NavLink className="prepend-icon"
                                        to="/app/agenda/meeting-reasons"
                                        isActive={(match, location) => location.pathname.startsWith('/app/agenda/meeting-reasons')}>
                                    <span className="nav-text"><IntlMessages id="sidebar.agenda.meeting-reason"/></span>
                                </NavLink>
                            </li>)}
                            <li>
                                <NavLink className="prepend-icon"
                                        to="/app/agenda/contacts"
                                        isActive={(match, location) => location.pathname.startsWith('/app/agenda/contacts')}>
                                    <span className="nav-text"><IntlMessages id="chat.contacts"/></span>
                                </NavLink>
                            </li>
                            {menuAdmin && (<li>
                                <NavLink className="prepend-icon"
                                        to="/app/agenda/roles"
                                        isActive={(match, location) => location.pathname.startsWith('/app/agenda/roles')}>
                                    <span className="nav-text"><IntlMessages id="sidebar.contacts.roles"/></span>
                                </NavLink>
                            </li>)}
                        </ul>
                    </li>

                    <li className={(menuPipeline && (menuPipelineLinear || menuPipelineDigital || menuAdmin))  ? 'ui_tooltip menu' : 'ui_tooltip menu sidenav-menu-hidden'}>
                        <a role="button">
                        <i className="fa-solid fa-chart-column"></i>
                            <span className="nav-text"><IntlMessages id="sidebar.pipeline"/></span>
                        </a>
                        <ul className="sub-menu">
                            {menuPipelineLinear && (
                                <li>
                                    <NavLink className="prepend-icon"
                                            to="/app/pipeline/forecast/1"
                                            isActive={(match, location) => location.pathname.startsWith('/app/pipeline/forecast/1')}>
                                        <span className="nav-text"><IntlMessages id="sidebar.pipeline.forecast-1"/></span>
                                    </NavLink>
                                </li>
                            )}
                            {menuPipelineDigital && (
                                <li>
                                    <NavLink className="prepend-icon"
                                            to="/app/pipeline/forecast/2"
                                            isActive={(match, location) => location.pathname.startsWith('/app/pipeline/forecast/2')}>
                                        <span className="nav-text"><IntlMessages id="sidebar.pipeline.forecast-2"/></span>
                                    </NavLink>
                                </li>
                            )}
                            {menuAdmin && (
                                <li>
                                    <NavLink className="prepend-icon"
                                            to="/app/pipeline/forecast/deleted"
                                            isActive={(match, location) => location.pathname.startsWith('/app/pipeline/forecast/deleted')}>
                                        <span className="nav-text"><IntlMessages id="sidebar.pipeline.forecast.deleted"/></span>
                                    </NavLink>
                                </li>
                            )}
                        </ul>
                    </li>

                    <li className={(menuTracker || menuTrackerPillar) ? 'ui_tooltip menu' : 'ui_tooltip menu sidenav-menu-hidden'}>
                        <a role="button">
                            <i className="fa fa-table"></i>
                            <span className="nav-text"><IntlMessages id="sidebar.tracker"/></span>
                        </a>
                        <ul className="sub-menu">
                            {menuTracker && (
                            <li>
                                <NavLink className="prepend-icon"
                                         to="/app/tracker/brandsolutions"
                                         isActive={(match, location) => location.pathname.startsWith('/app/tracker/brandsolutions')}>
                                    <span className="nav-text"><IntlMessages id="sidebar.tracker.brandsolutions"/></span>
                                </NavLink>
                            </li>)}
                            {menuTrackerPillar && (
                            <li>
                                <NavLink className="prepend-icon"
                                         to="/app/tracker/pillar"
                                         isActive={(match, location) => location.pathname.startsWith('/app/tracker/pillar')}>
                                    <span className="nav-text"><IntlMessages id="sidebar.tracker.pillar"/></span>
                                </NavLink>
                            </li>)}
                        </ul>
                    </li>

                    <li className={menuOverbooking || menuOverbookingBacino ? 'ui_tooltip menu' : 'ui_tooltip menu sidenav-menu-hidden'}>
                        <a role="button">
                            <i className="fa-regular fa-calendar-circle-exclamation"></i>
                            <span className="nav-text"><IntlMessages id="sidebar.overbooking"/></span>
                        </a>
                        <ul className="sub-menu">
                            <li>
                                <NavLink className="prepend-icon"
                                         to="/app/overbooking/overbooking"
                                         isActive={(match, location) => location.pathname.startsWith('/app/overbooking')}>
                                    <span className="nav-text"><IntlMessages id="sidebar.overbooking"/></span>
                                </NavLink>
                            </li>
                        </ul>
                    </li>


                    <li className={menuCommissionsManagement || menuCommissionStatement ? 'ui_tooltip menu' : 'ui_tooltip menu sidenav-menu-hidden'}>
                        <a role="button">
                            <i class="fa-solid fa-coins"></i>
                            <span className="nav-text"><IntlMessages id="sidebar.commissions"/></span>
                        </a>

                        <ul className="sub-menu">
                            {menuCommissionsManagement && (
                                <li>
                                    <NavLink className="prepend-icon"
                                            to="/app/commissions/list"
                                            isActive={(match, location) => location.pathname.startsWith('/app/commissions/list')}>
                                        <span className="nav-text"><IntlMessages id="sidebar.commissions"/></span>
                                    </NavLink>
                                </li>
                            )}
                            {menuCommissionsManagement && (
                                <li>
                                    <NavLink className="prepend-icon"
                                            to="/app/commissions/settings"
                                            isActive={(match, location) => location.pathname.startsWith('/app/commissions/settings')}>
                                        <span className="nav-text"><IntlMessages id="sidebar.manage.commissions"/></span>
                                    </NavLink>
                                </li>
                            )}
                            {menuCommissionsManagement && (
                                <li>
                                    <NavLink className="prepend-icon"
                                            to="/app/commissions/portfolio-variations"
                                            isActive={(match, location) => location.pathname.startsWith('/app/commissions/portfolio-variations')}>
                                        <span className="nav-text"><IntlMessages id="sidebar.manage.portfolio.variations"/></span>
                                    </NavLink>
                                </li>
                            )}
                            {(menuCommissionsManagement || menuCommissionStatement) && (
                                <li>
                                    <NavLink className="prepend-icon"
                                            to="/app/commissions/sales-commission-statements"
                                            isActive={(match, location) => location.pathname.startsWith('/app/commissions/sales-commission-statements')}>
                                        <span className="nav-text"><IntlMessages id="sidebar.sales.commissions.statements"/></span>
                                    </NavLink>
                                </li>  
                            )}       
                            {(menuCommissionsManagement) && (
                                <li>
                                    <NavLink className="prepend-icon"
                                            to="/app/commissions/sales-commission-summary"
                                            isActive={(match, location) => location.pathname.startsWith('/app/commissions/sales-commission-summary')}>
                                        <span className="nav-text"><IntlMessages id="sidebar.sales.commissions.summary"/></span>
                                    </NavLink>
                                </li>  
                            )}                                     
                        </ul>
                    </li>

                    <li className='ui_tooltip menu'>
                        <a role="button">
                            <i class="fa-solid fa-briefcase"></i>
                            <span className="nav-text"><IntlMessages id="sidebar.portfolio"/></span>
                        </a>

                        <ul className="sub-menu">
                            <li>
                                <NavLink className="prepend-icon"
                                        to="/app/portfolio/portfolio-actual"
                                        isActive={(match, location) => location.pathname.startsWith('/app/portfolio/portfolio-actual')}>
                                    <span className="nav-text"><IntlMessages id="sidebar.portfolio.actual"/></span>
                                </NavLink>
                            </li>
                        </ul>
                    </li>
                    
                    <li className={menuAdmin ? 'ui_tooltip menu' : 'ui_tooltip menu sidenav-menu-hidden'}>
                        <a role="button">
                        <i class="fa-regular fa-folder-gear"></i>
                            <span className="nav-text"><IntlMessages id="sidebar.options"/></span>
                        </a>

                        <ul className="sub-menu">

                            <li>
                                <NavLink className="prepend-icon"
                                         to="/app/options/advertisers"
                                         isActive={(match, location) => location.pathname.startsWith('/app/options/advertisers')}>
                                    <span className="nav-text"><IntlMessages id="sidebar.advertisers"/></span>
                                </NavLink>
                            </li>

                            <li>
                                <NavLink className="prepend-icon"
                                         to="/app/options/channels"
                                         isActive={(match, location) => location.pathname.startsWith('/app/options/channels')}>
                                    <span className="nav-text"><IntlMessages id="sidebar.channels"/></span>
                                </NavLink>
                            </li>

                            <li>
                                <NavLink className="prepend-icon"
                                         to="/app/options/deliveries"
                                         isActive={(match, location) => location.pathname.startsWith('/app/options/deliveries')}>
                                    <span className="nav-text"><IntlMessages id="sidebar.deliveries"/></span>
                                </NavLink>
                            </li>

                            <li>
                                <NavLink className="prepend-icon"
                                         to="/app/options/kubik-audiences"
                                         isActive={(match, location) => location.pathname.startsWith('/app/options/kubik-audiences')}>
                                    <span className="nav-text"><IntlMessages id="sidebar.kubikaudiences"/></span>
                                </NavLink>
                            </li>

                            <li>
                                <NavLink className="prepend-icon"
                                         to="/app/options/mapping"
                                         isActive={(match, location) => location.pathname.startsWith('/app/options/mapping')}>
                                    <span className="nav-text"><IntlMessages id="sidebar.mapping"/></span>
                                </NavLink>
                            </li>
                        
                            <li>
                                <NavLink className="prepend-icon"
                                        to="/app/usersList"
                                        isActive={(match, location) => location.pathname.startsWith('/app/usersList')}>
                                    <span className="nav-text"><IntlMessages id="sidebar.users"/></span>
                                </NavLink>
                            </li>

                            <li>
                                <NavLink className="prepend-icon"
                                        to="/app/usersRoles"
                                        isActive={(match, location) => location.pathname.startsWith('/app/usersRoles')}>
                                    <span className="nav-text"><IntlMessages id="sidebar.users.roles"/></span>
                                </NavLink>
                            </li>

                            <li>
                                <NavLink className="prepend-icon"
                                        to="/app/UserLoginHistory"
                                        isActive={(match, location) => location.pathname.startsWith('/app/UserLoginHistory')}>
                                    <span className="nav-text"><IntlMessages id="sidebar.loghistory"/></span>
                                </NavLink>
                            </li>

                            <li>
                                <NavLink className="prepend-icon"
                                         to="/app/options/proposals"
                                         isActive={(match, location) => location.pathname.startsWith('/app/options/proposals')}>
                                    <span className="nav-text"><IntlMessages id="sidebar.proposals"/></span>
                                </NavLink>
                            </li>

                        </ul>
                    </li>

                    {/*
                    <li className={menuAdmin ? 'ui_tooltip menu' : 'ui_tooltip menu sidenav-menu-hidden'}>
                        <a role="button">
                        <i class="fa-solid fa-map-pin"></i>
                            <span className="nav-text"><IntlMessages id="sidebar.alverman"/></span>
                        </a>

                        <ul className="sub-menu">
                            <li>
                                <NavLink className="prepend-icon"
                                         to="/app/mapping"
                                         isActive={(match, location) => location.pathname.startsWith('/app/mapping')}>
                                    <span className="nav-text"><IntlMessages id="sidebar.alverman.mapping"/></span>
                                </NavLink>
                            </li>

                            <li>
                                <NavLink className="prepend-icon"
                                         to="/app/alert"
                                         isActive={(match, location) => location.pathname.startsWith('/app/alert')}>
                                    <span className="nav-text"><IntlMessages id="sidebar.alverman.alert"/></span>
                                </NavLink>
                            </li>
                        
                       
                        </ul>
                    </li>
                    */}

                    <li className={menuRoobeek ? 'ui_tooltip' : 'ui_tooltip sidenav-menu-hidden'}>
                    
                        <a href={`${process.env.ROOBEEK_URL}?token=${localStorage.getItem ('user_id')}`}
                           target="_blank">
                            <img src="assets/images/roobeek-32.png" width="20" />
                            <span className="nav-text" style={{marginLeft:12}}><IntlMessages id="sidebar.roobeek"/></span>
                        </a>
                    </li>

                    </ul>
                    <a className='sys-logo-container' href='https://www.systrategy.it/' target='_blank'>
                        <img className='sys-logo-cube' src='assets/images/systrategy_cube_logo.svg'></img>
                        <img className='sys-logo-word' src='assets/images/systrategy_word_logo.svg'></img>
                    </a>
            </CustomScrollbars>
        );
    }
}

export default withRouter(SidenavContent);
