/* eslint-disable no-restricted-globals */
import React from 'react';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom'
import { connect } from 'react-redux';
import { NotificationManager } from 'react-notifications';
import IntlMessages from 'util/IntlMessages';
import {
    hideMessage,
    showAuthLoader,
    userSignIn,
} from 'actions/Auth';
import CircularProgress from 'components/CircularProgress'

import Input from '@material-ui/core/Input';
import {createMuiTheme, MuiThemeProvider} from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import AccountCircle from '@material-ui/icons/AccountCircle';
import VpnKey from '@material-ui/icons/VpnKey';
import ReCAPTCHA from 'react-google-recaptcha';

const recaptcha_public_key = process.env.RECAPTCHA_PUBLIC_KEY;

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#2789d8',
        },
        secondary: {
            main: '#7b7b7b',
        },
    }
});

class SignIn extends React.Component {
    constructor () {
        super ();
        this.state = {
            email: '',
            password: '',
            recaptchaValue: '',
        }
    }

    componentDidMount(){
        if(this.props.location.state !== undefined){window.location.reload()}
    }

    componentDidUpdate () {
        if (this.props.showMessage) {
            setTimeout (() => {
                this.props.hideMessage ();
            }, 100);
        }
        if (this.props.authUser !== null) {
            this.props.history.push ('/');
        }
    }

    handleChange(event){
        this.setState({ [event.target.name] : event.target.value});
    }

    handleClickShowPassword(event){
        this.setState({ showPassword : !this.state.showPassword});
    }


    handleMouseDownPassword(event){
        event.preventDefault();
    }

    handleSubmit(event) {
        event.preventDefault();

        const email = this.state.email;
        const password = this.state.password;
        const recaptchaValue = this.state.recaptchaValue;

        if(recaptchaValue === '' && process.env.RECAPTCHA_CHECK_ENABLED === true){
            NotificationManager.error('Captcha is not valid', '');
            return;
        }

        this.props.showAuthLoader();
        this.props.userSignIn({email, password, recaptchaValue});
        window.grecaptcha.reset();
    }

    handleCaptchaChange     = async ( recaptchaValue ) => {
        this.setState( () => ({ recaptchaValue }) );
    };

    handleCaptchaExpire = async () => {
        this.setState( () => ({ recaptchaValue: '' }) );
    };

    render () {
        const {
            email,
            password,
            showPassword,
        } = this.state;
        const { showMessage, loader, alertMessage } = this.props;
        return (
            <div className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
                <div className="app-login-main-content">

                    <MuiThemeProvider theme={theme}>

                        <div className="app-logo-content d-flex align-items-center justify-content-center">
                            <Link className="logo-lg" to="/" title="Jambo">
                                <img src="assets/images/marchio_crm-analyzer.svg" alt="crmanalyzer" title="crmanalyzer" />

                            </Link>
                        </div>

                        <div className="app-login-content">
                            <div className="app-login-header" style={{marginBottom: 10}}>
                                <div className="sys-logo">systrategy</div>
                                <h1><span className="login-media">crm</span><span className="login-anal">analyzer</span></h1>
                            </div>

                            <div className="app-login-form">
                                <form onSubmit={this.handleSubmit.bind(this)}>
                                    <div className="form-group mb-3">
                                        <FormControl className="login-credentials-wrapper">
                                            <TextField
                                                name="email"
                                                value={email}
                                                placeholder="Username"
                                                onChange={this.handleChange.bind(this)}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <AccountCircle color="secondary" />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </FormControl>
                                    </div>

                                    <div className="form-group mb-3">
                                        <FormControl className="login-credentials-wrapper">
                                            <Input
                                                name="password"
                                                id="password"
                                                placeholder="Password"
                                                type={showPassword ? 'text' : 'password'}
                                                value={password}
                                                onChange={() => this.handleChange(event)}
                                                startAdornment={
                                                    <InputAdornment position="start">
                                                        <VpnKey color="secondary" />
                                                    </InputAdornment>
                                                }
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={() => this.handleClickShowPassword(event)}
                                                            onMouseDown={() => this.handleMouseDownPassword(event)}
                                                        >
                                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                            />
                                        </FormControl>
                                    </div>

                                    {process.env.RECAPTCHA_CHECK_ENABLED && (
                                        <div className="g-recaptcha">
                                            <ReCAPTCHA
                                                sitekey   = { recaptcha_public_key     }
                                                onChange  = { this.handleCaptchaChange }
                                                onExpired = { this.handleCaptchaExpire }
                                                onErrored = { this.handleCaptchaExpire }
                                            />
                                        </div>
                                    )}                                    

                                    <div className="mb-3 d-flex align-items-center justify-content-between" style={{marginTop: 20}}>
                                        <Button type="submit" 
                                                color="primary" 
                                                className="text-uppercase login-btn">                                                
                                            <IntlMessages id="appModule.signIn" />
                                        </Button>
                                    </div>

                                    <div className='lbl-framework'><a href="https://www.systrategy.it/media-e-advertising/" target="_blank">Systrategy Analysis Framework</a></div>

                                </form>
                            </div>
                        </div>
                    </MuiThemeProvider>

                </div>
                {
                    loader &&
                    <div className="loader-view">
                        <CircularProgress />
                    </div>
                }
                {showMessage && NotificationManager.error (alertMessage)}
            </div>
        );
    }
}

const mapStateToProps = ({ auth }) => {
    const { loader, alertMessage, showMessage, authUser } = auth;
    return { loader, alertMessage, showMessage, authUser }
};

export default connect (mapStateToProps, {
    userSignIn,
    hideMessage,
    showAuthLoader,
}) (SignIn);
