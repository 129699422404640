import React from "react";
import "./RoleList.css";
import IntlMessages from 'util/IntlMessages';
import NotificationWrapper from "util/NotificationWrapper";
import {Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import {connect} from "react-redux";
// import {userSignOut} from 'actions/Auth';

import {
    Card,
    CardBody,
    CardHeader,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane
} from "reactstrap";
import classnames from "classnames";
import FetchService from 'services/FetchService';
import Nprogress from "nprogress";
import Button from '@material-ui/core/Button';
import {MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
    palette: {
        primary: {
            light: '#fff',
            main: '#2789d8',

        },
        secondary: {
            main: '#f44336',
        },
    }
});

class RoleList extends React.Component {

    icoPriv = [];

    constructor(props) {
        super(props);

        const newRole = {
          P_AGENZIE:                  'N'
          ,P_ALERTS:                  'N'
          ,P_BUSINESS_TYPES:          'N'
          ,P_CANALI:                  'N'
          ,P_CB_CONTRACTS:            'N'
          ,P_CENTRI_MEDIA:            'N'
          ,P_CLIENTI:                 'N'     
          ,P_CLIENTI_AGENZIE:         'N'
          ,P_FATTORI_CONVERSIONE:     'N'
          ,P_IMPERSONATE:             'N'
          ,P_LOAD_ARIANNA_GRP:        'N'
          ,P_LOAD_DISCOVERY_ANAG:     'N'
          ,P_LOAD_DISCOVERY_INV:      'N'
          ,P_LOAD_NIELSEN_INV_GRP:    'N'
          ,P_LOAD_NIELSEN_INV_SHR:    'N'
          ,P_MAP_CANALI_ARIANNA:      'N'
          ,P_MAP_CANALI_DISCOVERY:    'N'
          ,P_MAP_CLIENTI_ARIANNA:     'N'
          ,P_MAP_CLIENTI_DISCOVERY:   'N'
          ,P_MAP_CLIENTI_NIELSEN:     'N'
          ,P_MAP_TARGET_ARIANNA:      'N'
          ,P_MAP_TARGET_NIELSEN:      'N'
          ,P_POL_COMM:                'N'
          ,P_POL_COMM_IMPORT:         'N'
          ,P_PORTAFOGLIO:             'N'
          ,P_PRODOTTI_ARIANNA:        'N'
          ,P_PRODOTTI_DISCOVERY:      'N'
          ,P_PRODOTTI_NIELSEN:        'N'
          ,P_REPORT_LAST_DATES:       'N'
          ,P_RESTRICTED_ACCESS:       'N'
          ,P_ROOBEEK:                 'W'
          ,P_RPT_COMPETITORS_ANNO:    'N'
          ,P_RPT_DISCOVERY_ANNO_MESE: 'N'
          ,P_TARGET: 'N'
          ,P_PIPELINE_FORECAST: 'N'
          ,P_PIPELINE_SNAPSHOT: 'N'
          ,P_TRACKER_AVAILAB: 'N'
          ,P_TRACKER_PILLAR: 'N'
          ,P_OVERBOOKING: 'N'
          ,P_OVERBOOKING_BACINO: 'N'
          ,P_PROVV_COMMISSIONS: 'N'
          ,P_PROVV_STATEMENTS: 'N'
          ,P_AGENDA: 'N'
        }

        this.toggle = this.toggle.bind(this);
        this.state = {
            roles: [],
            activeTab: '',
            roleDescriptions: [],
            newRoleDescription: '',
            newRole: newRole,
            modalNewRole: false,
            loading: true,
        };

        this.icoPriv['W'] = <div className="write alert-success" title="Write"><i className="zmdi zmdi-edit zmdi-hc-fw"></i></div>;
        this.icoPriv['R'] = <div className="read alert-warning" title="Read"><i className="zmdi zmdi-eye zmdi-hc-fw"></i></div>;
        this.icoPriv['N'] = <div className="denied alert-secondary" title="Denied"><i className="zmdi zmdi-block zmdi-hc-fw"></i></div>;

        this.toggleDelRole = this.toggleDelRole.bind(this);
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });

            if(tab === 'new'){
                this.setState({
                    newRole: {
                      P_AGENZIE:                  'N'
                      ,P_ALERTS:                  'N'
                      ,P_BUSINESS_TYPES:          'N'
                      ,P_CANALI:                  'N'
                      ,P_CB_CONTRACTS:            'N'
                      ,P_CENTRI_MEDIA:            'N'
                      ,P_CLIENTI:                 'N'           
                      ,P_CLIENTI_AGENZIE:         'N'
                      ,P_FATTORI_CONVERSIONE:     'N'
                      ,P_IMPERSONATE:             'N'
                      ,P_LOAD_ARIANNA_GRP:        'N'
                      ,P_LOAD_DISCOVERY_ANAG:     'N'
                      ,P_LOAD_DISCOVERY_INV:      'N'
                      ,P_LOAD_NIELSEN_INV_GRP:    'N'
                      ,P_LOAD_NIELSEN_INV_SHR:    'N'
                      ,P_MAP_CANALI_ARIANNA:      'N'
                      ,P_MAP_CANALI_DISCOVERY:    'N'
                      ,P_MAP_CLIENTI_ARIANNA:     'N'
                      ,P_MAP_CLIENTI_DISCOVERY:   'N'
                      ,P_MAP_CLIENTI_NIELSEN:     'N'
                      ,P_MAP_TARGET_ARIANNA:      'N'
                      ,P_MAP_TARGET_NIELSEN:      'N'
                      ,P_POL_COMM:                'N'
                      ,P_POL_COMM_IMPORT:         'N'
                      ,P_PORTAFOGLIO:             'N'
                      ,P_PRODOTTI_ARIANNA:        'N'
                      ,P_PRODOTTI_DISCOVERY:      'N'
                      ,P_PRODOTTI_NIELSEN:        'N'
                      ,P_REPORT_LAST_DATES:       'N'
                      ,P_RESTRICTED_ACCESS:       'N'
                      ,P_ROOBEEK:                 'N'
                      ,P_RPT_COMPETITORS_ANNO:    'N'
                      ,P_RPT_DISCOVERY_ANNO_MESE: 'N'
                      ,P_TARGET:                  'N'
                      ,P_PIPELINE_FORECAST: 'N'
                      ,P_PIPELINE_SNAPSHOT: 'N'
                      ,P_TRACKER_AVAILAB: 'N'
                      ,P_TRACKER_PILLAR: 'N'
                      ,P_OVERBOOKING: 'N'
                      ,P_OVERBOOKING_BACINO: 'N'
                      ,P_PROVV_COMMISSIONS: 'N'
                      ,P_PROVV_STATEMENTS: 'N'
                      ,P_AGENDA: 'N'
                    },
                    newRoleDescription: ''
                });

            }
        }
    }

    toggleDelRole() {
        this.setState({
            modalNewRole: !this.state.modalNewRole
        });
    }

    componentDidMount() {
        this.getRoles();
    }

    getRoles(tab=""){
        FetchService.get("/usersRole/role-list")
            .then((data) => {

                let activeTab = '';
                if(data.length>0){
                    activeTab = (data[0]["ROLE_COD"]).toString();
                }

                let roleDescriptions = [];
                for (let i = 0; i < data.length; i++) {
                    roleDescriptions[data[i]["ROLE_COD"]] = data[i]["ROLE_DESC"];
                }

                this.setState({
                    roles: data,
                    loading: false,
                    activeTab: activeTab,
                    roleDescriptions: roleDescriptions
                });

                if(tab != ""){
                    this.toggle(tab);
                }

            })
            .catch((error) => {
                // this.props.userSignOut()
            });
    }

    createTabsHeader() {
        let tabs = [];
        const roles = this.state.roles;
        const newIdTab = "new";

        for (let i = 0; i < roles.length; i++) {
            const idTab = (roles[i]["ROLE_COD"]).toString();
            const roleName = roles[i]["ROLE_DESC"];

            tabs.push(
                <NavItem key={idTab} title={roleName}>
                    <NavLink
                        className={classnames({active: this.state.activeTab === idTab})}
                        onClick={() => {
                            this.toggle(idTab);
                        }}>
                        {roleName}
                    </NavLink>
                </NavItem>);
        }

        tabs.push(
            <NavItem key={newIdTab} title="New Role">
                <NavLink
                    className={classnames({active: this.state.activeTab === newIdTab, new: true})}
                    onClick={() => {
                        this.toggle(newIdTab);
                    }}>
                    <i className="zmdi zmdi-collection-plus zmdi-hc-fw"></i>New Role
                </NavLink>
            </NavItem>);

        return tabs;
    }

    handleChangeDesc(index, event) {
        let roleDescriptions = this.state.roleDescriptions;
        roleDescriptions[index] = event.target.value;
        this.setState({ roleDescriptions : roleDescriptions});
    }

    handleChangeNewDesc(event) {
        this.setState({ newRoleDescription : event.target.value});
    }

    createTabsBody() {
        let tabs = [];
        const roles = this.state.roles;

        for (let i = 0; i < roles.length; i++) {
            const idTab = (roles[i]["ROLE_COD"]).toString();
            const roleName = roles[i]["ROLE_DESC"];

            tabs.push(
                <TabPane tabId={idTab} key={idTab}>
                    <CardBody>

                        <div className="row">
                            <div className="col-lg-4 col-md-6 col-sm-12">
                                <div className="form-group">
                                    <input type="text" name="roleDescriptions" className="form-control"
                                           placeholder="Role description"
                                           onChange={this.handleChangeDesc.bind(this, idTab)}
                                           value={this.state.roleDescriptions[idTab]}/>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12">
                                <div className="privileges-list title">Upload / Import</div>

                                <div className="privileges-list">Nielsen (AQX): Standard Investments / GRP
                                    <div className="privileges-ico ico-link" onClick={() => this.changeIcoPriv("P_LOAD_NIELSEN_INV_GRP", i)}>{this.icoPriv[roles[i].P_LOAD_NIELSEN_INV_GRP]}</div>
                                </div>

                                <div className="privileges-list">Arianna: GRP
                                    <div className="privileges-ico ico-link" onClick={() => this.changeIcoPriv("P_LOAD_ARIANNA_GRP", i)}>{this.icoPriv[roles[i].P_LOAD_ARIANNA_GRP]}</div>
                                </div>

                                <div className="privileges-list">Discovery (BTS): Advertisers / Products / Agents / Agents Holdings
                                    <div className="privileges-ico ico-link" onClick={() => this.changeIcoPriv("P_LOAD_DISCOVERY_ANAG", i)}>{this.icoPriv[roles[i].P_LOAD_DISCOVERY_ANAG]}</div>
                                </div>

                                <div className="privileges-list">Discovery (BTS): Standard Investments / Share Investments
                                    <div className="privileges-ico ico-link" onClick={() => this.changeIcoPriv("P_LOAD_DISCOVERY_INV", i)}>{this.icoPriv[roles[i].P_LOAD_DISCOVERY_INV]}</div>
                                </div>
                                

                                <div className="privileges-list title">General Privileges</div>

                                <div className="privileges-list">Agents Holdings management
                                    <div className="privileges-ico ico-link" onClick={() => this.changeIcoPriv("P_CENTRI_MEDIA", i)}>{this.icoPriv[roles[i].P_CENTRI_MEDIA]}</div>
                                </div>
                                <div className="privileges-list">Agents management
                                    <div className="privileges-ico ico-link" onClick={() => this.changeIcoPriv("P_AGENZIE", i)}>{this.icoPriv[roles[i].P_AGENZIE]}</div>
                                </div>                                
                                <div className="privileges-list">Forced agents management
                                    <div className="privileges-ico ico-link" onClick={() => this.changeIcoPriv("P_CLIENTI_AGENZIE", i)}>{this.icoPriv[roles[i].P_CLIENTI_AGENZIE]}</div>
                                </div> 
                                
                                <div className="privileges-list">Channels management
                                    <div className="privileges-ico ico-link" onClick={() => this.changeIcoPriv("P_CANALI", i)}>{this.icoPriv[roles[i].P_CANALI]}</div>
                                </div> 

                                <div className="privileges-list">Targets management
                                    <div className="privileges-ico ico-link" onClick={() => this.changeIcoPriv("P_TARGET", i)}>{this.icoPriv[roles[i].P_TARGET]}</div>
                                </div>

                                <div className="privileges-list">Business types management
                                    <div className="privileges-ico ico-link" onClick={() => this.changeIcoPriv("P_BUSINESS_TYPES", i)}>{this.icoPriv[roles[i].P_BUSINESS_TYPES]}</div>
                                </div> 


                                <div className="privileges-list">Report last dates management
                                    <div className="privileges-ico ico-link" onClick={() => this.changeIcoPriv("P_REPORT_LAST_DATES", i)}>{this.icoPriv[roles[i].P_REPORT_LAST_DATES]}</div>
                                </div>

                                <div className="privileges-list">Conversion factors management
                                    <div className="privileges-ico ico-link" onClick={() => this.changeIcoPriv("P_FATTORI_CONVERSIONE", i)}>{this.icoPriv[roles[i].P_FATTORI_CONVERSIONE]}</div>
                                </div>

                                <div className="privileges-list">Alerts
                                    <div className="privileges-ico ico-link" onClick={() => this.changeIcoPriv("P_ALERTS", i)}>{this.icoPriv[roles[i].P_ALERTS]}</div>
                                </div>

                                <div className="privileges-list">Impersonate
                                    <div className="privileges-ico ico-link" onClick={() => this.changeIcoPriv("P_IMPERSONATE", i)}>{this.icoPriv[roles[i].P_IMPERSONATE]}</div>
                                </div>

                                <div className="privileges-list">Restricted Access
                                    <div className="privileges-ico ico-link" onClick={() => this.changeIcoPriv("P_RESTRICTED_ACCESS", i)}>{this.icoPriv[roles[i].P_RESTRICTED_ACCESS]}</div>
                                </div>


                                <div className="privileges-list">Portfolio
                                    <div className="privileges-ico ico-link" onClick={() => this.changeIcoPriv("P_PORTAFOGLIO", i)}>{this.icoPriv[roles[i].P_PORTAFOGLIO]}</div>
                                </div>


                                <div className="privileges-list title">Mapping</div>
                                
                                <div className="privileges-list">Nielsen Advertisers
                                    <div className="privileges-ico ico-link" onClick={() => this.changeIcoPriv("P_MAP_CLIENTI_NIELSEN", i)}>{this.icoPriv[roles[i].P_MAP_CLIENTI_NIELSEN]}</div>
                                </div>

                                <div className="privileges-list">Discovery Advertisers
                                    <div className="privileges-ico ico-link" onClick={() => this.changeIcoPriv("P_MAP_CLIENTI_DISCOVERY", i)}>{this.icoPriv[roles[i].P_MAP_CLIENTI_DISCOVERY]}</div>
                                </div>

                                <div className="privileges-list">Arianna Advertisers
                                    <div className="privileges-ico ico-link" onClick={() => this.changeIcoPriv("P_MAP_CLIENTI_ARIANNA", i)}>{this.icoPriv[roles[i].P_MAP_CLIENTI_ARIANNA]}</div>
                                </div>

                                <div className="privileges-list">Nielsen Targets
                                    <div className="privileges-ico ico-link" onClick={() => this.changeIcoPriv("P_MAP_TARGET_NIELSEN", i)}>{this.icoPriv[roles[i].P_MAP_TARGET_NIELSEN]}</div>
                                </div>

                                <div className="privileges-list">Arianna Targets
                                    <div className="privileges-ico ico-link" onClick={() => this.changeIcoPriv("P_MAP_TARGET_ARIANNA", i)}>{this.icoPriv[roles[i].P_MAP_TARGET_ARIANNA]}</div>
                                </div>

                                <div className="privileges-list">Arianna Channels
                                    <div className="privileges-ico ico-link" onClick={() => this.changeIcoPriv("P_MAP_CANALI_ARIANNA", i)}>{this.icoPriv[roles[i].P_MAP_CANALI_ARIANNA]}</div>
                                </div>

                                <div className="privileges-list">Discovery Channels
                                    <div className="privileges-ico ico-link" onClick={() => this.changeIcoPriv("P_MAP_CANALI_DISCOVERY", i)}>{this.icoPriv[roles[i].P_MAP_CANALI_DISCOVERY]}</div>
                                </div>


                              <div className="privileges-list title">Advertisers / Products</div>
                                <div className="privileges-list">Advertisers management
                                    <div className="privileges-ico ico-link" onClick={() => this.changeIcoPriv("P_CLIENTI", i)}>{this.icoPriv[roles[i].P_CLIENTI]}</div>
                                </div>

                                <div className="privileges-list">Nielsen Products management
                                    <div className="privileges-ico ico-link" onClick={() => this.changeIcoPriv("P_PRODOTTI_NIELSEN", i)}>{this.icoPriv[roles[i].P_PRODOTTI_NIELSEN]}</div>
                                </div>

                                <div className="privileges-list">Arianna Products management
                                    <div className="privileges-ico ico-link" onClick={() => this.changeIcoPriv("P_PRODOTTI_ARIANNA", i)}>{this.icoPriv[roles[i].P_PRODOTTI_ARIANNA]}</div>
                                </div>
                                <div className="privileges-list title">Reports</div>
                                <div className="privileges-list">Discovery 
                                    <div className="privileges-ico ico-link" onClick={() => this.changeIcoPriv("P_RPT_DISCOVERY_ANNO_MESE", i)}>{this.icoPriv[roles[i].P_RPT_DISCOVERY_ANNO_MESE]}</div>
                                </div>

                                <div className="privileges-list">Competitors
                                    <div className="privileges-ico ico-link" onClick={() => this.changeIcoPriv("P_RPT_COMPETITORS_ANNO", i)}>{this.icoPriv[roles[i].P_RPT_COMPETITORS_ANNO]}</div>
                                </div>

                                <div className="privileges-list title">DasMarianna</div>
                                <div className="privileges-list">General
                                    <div className="privileges-ico ico-link" onClick={() => this.changeIcoPriv("P_POL_COMM", i)}>{this.icoPriv[roles[i].P_POL_COMM]}</div>
                                </div>

                                <div className="privileges-list">Import
                                    <div className="privileges-ico ico-link" onClick={() => this.changeIcoPriv("P_POL_COMM_IMPORT", i)}>{this.icoPriv[roles[i].P_POL_COMM_IMPORT]}</div>
                                </div>

                                <div className="privileges-list title">Robeek</div>
                                <div className="privileges-list">General
                                    <div className="privileges-ico ico-link" onClick={() => this.changeIcoPriv("P_ROOBEEK", i)}>{this.icoPriv[roles[i].P_ROOBEEK]}</div>
                                </div>

                                <div className="privileges-list title">Crm Analyzer</div>
                                <div className="privileges-list">Pipeline Forecast
                                    <div className="privileges-ico ico-link" onClick={() => this.changeIcoPriv("P_PIPELINE_FORECAST", i)}>{this.icoPriv[roles[i].P_PIPELINE_FORECAST]}</div>
                                </div>

                                <div className="privileges-list">Pipeline Snapshot
                                    <div className="privileges-ico ico-link" onClick={() => this.changeIcoPriv("P_PIPELINE_SNAPSHOT", i)}>{this.icoPriv[roles[i].P_PIPELINE_SNAPSHOT]}</div>
                                </div>

                                <div className="privileges-list">Tracker Brand Solutions
                                    <div className="privileges-ico ico-link" onClick={() => this.changeIcoPriv("P_TRACKER_AVAILAB", i)}>{this.icoPriv[roles[i].P_TRACKER_AVAILAB]}</div>
                                </div>

                                <div className="privileges-list">Tracker Pillar
                                    <div className="privileges-ico ico-link" onClick={() => this.changeIcoPriv("P_TRACKER_PILLAR", i)}>{this.icoPriv[roles[i].P_TRACKER_PILLAR]}</div>
                                </div>

                                <div className="privileges-list">Overbooking
                                    <div className="privileges-ico ico-link" onClick={() => this.changeIcoPriv("P_OVERBOOKING", i)}>{this.icoPriv[roles[i].P_OVERBOOKING]}</div>
                                </div>

                                <div className="privileges-list">Overbooking (advertising pool)
                                    <div className="privileges-ico ico-link" onClick={() => this.changeIcoPriv("P_OVERBOOKING_BACINO", i)}>{this.icoPriv[roles[i].P_OVERBOOKING_BACINO]}</div>
                                </div>

                                <div className="privileges-list">Commission Management
                                    <div className="privileges-ico ico-link" onClick={() => this.changeIcoPriv("P_PROVV_COMMISSIONS", i)}>{this.icoPriv[roles[i].P_PROVV_COMMISSIONS]}</div>
                                </div>

                                <div className="privileges-list">Commission Statements
                                    <div className="privileges-ico ico-link" onClick={() => this.changeIcoPriv("P_PROVV_STATEMENTS", i)}>{this.icoPriv[roles[i].P_PROVV_STATEMENTS]}</div>
                                </div>

                                <div className="privileges-list">Agenda
                                    <div className="privileges-ico ico-link" onClick={() => this.changeIcoPriv("P_AGENDA", i)}>{this.icoPriv[roles[i].P_AGENDA]}</div>
                                </div>
                            </div>
                        </div>
                    </CardBody>
                </TabPane>
            );
        }

        //tab new
        const newRole = this.state.newRole;
        const newIdTab = "new";
        tabs.push(
            <TabPane tabId={newIdTab} key={newIdTab}>
                <CardBody>
                    <div className="row">
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="form-group">
                                <input type="text" name="newRoleDescription" className="form-control"
                                       placeholder="Role description"
                                       onChange={this.handleChangeNewDesc.bind(this)}
                                       value={this.state.newRoleDescription}/>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                            <div className="col-12">
                                <div className="privileges-list title">Upload / Import</div>

                                <div className="privileges-list">Nielsen (AQX): Standard Investments / GRP
                                    <div className="privileges-ico ico-link" onClick={() => this.changeIcoPrivNew("P_LOAD_NIELSEN_INV_GRP")}>{this.icoPriv[newRole.P_LOAD_NIELSEN_INV_GRP]}</div>
                                </div>

                                <div className="privileges-list">Arianna: GRP
                                    <div className="privileges-ico ico-link" onClick={() => this.changeIcoPrivNew("P_LOAD_ARIANNA_GRP")}>{this.icoPriv[newRole.P_LOAD_ARIANNA_GRP]}</div>
                                </div>

                                <div className="privileges-list">Discovery (BTS): Advertisers / Products / Agents / Agents Holdings
                                    <div className="privileges-ico ico-link" onClick={() => this.changeIcoPrivNew("P_LOAD_DISCOVERY_ANAG")}>{this.icoPriv[newRole.P_LOAD_DISCOVERY_ANAG]}</div>
                                </div>

                                <div className="privileges-list">Discovery (BTS): Standard Investments / Share Investments
                                    <div className="privileges-ico ico-link" onClick={() => this.changeIcoPrivNew("P_LOAD_DISCOVERY_INV")}>{this.icoPriv[newRole.P_LOAD_DISCOVERY_INV]}</div>
                                </div>
                                

                                <div className="privileges-list title">General Privileges</div>

                                <div className="privileges-list">Agents Holdings management
                                    <div className="privileges-ico ico-link" onClick={() => this.changeIcoPrivNew("P_CENTRI_MEDIA")}>{this.icoPriv[newRole.P_CENTRI_MEDIA]}</div>
                                </div>
                                <div className="privileges-list">Agents management
                                    <div className="privileges-ico ico-link" onClick={() => this.changeIcoPrivNew("P_AGENZIE")}>{this.icoPriv[newRole.P_AGENZIE]}</div>
                                </div>                                
                                <div className="privileges-list">Forced agents management
                                    <div className="privileges-ico ico-link" onClick={() => this.changeIcoPrivNew("P_CLIENTI_AGENZIE")}>{this.icoPriv[newRole.P_CLIENTI_AGENZIE]}</div>
                                </div> 
                                
                                <div className="privileges-list">Channels management
                                    <div className="privileges-ico ico-link" onClick={() => this.changeIcoPrivNew("P_CANALI")}>{this.icoPriv[newRole.P_CANALI]}</div>
                                </div> 

                                <div className="privileges-list">Targets management
                                    <div className="privileges-ico ico-link" onClick={() => this.changeIcoPrivNew("P_TARGET")}>{this.icoPriv[newRole.P_TARGET]}</div>
                                </div>


                                
                                <div className="privileges-list">Business types management
                                    <div className="privileges-ico ico-link" onClick={() => this.changeIcoPrivNew("P_BUSINESS_TYPES")}>{this.icoPriv[newRole.P_BUSINESS_TYPES]}</div>
                                </div> 


                                <div className="privileges-list">Report last dates management
                                    <div className="privileges-ico ico-link" onClick={() => this.changeIcoPrivNew("P_REPORT_LAST_DATES")}>{this.icoPriv[newRole.P_REPORT_LAST_DATES]}</div>
                                </div>

                                <div className="privileges-list">Conversion factors management
                                    <div className="privileges-ico ico-link" onClick={() => this.changeIcoPrivNew("P_FATTORI_CONVERSIONE")}>{this.icoPriv[newRole.P_FATTORI_CONVERSIONE]}</div>
                                </div>

                                <div className="privileges-list">Alerts
                                    <div className="privileges-ico ico-link" onClick={() => this.changeIcoPrivNew("P_ALERTS")}>{this.icoPriv[newRole.P_ALERTS]}</div>
                                </div>

                                <div className="privileges-list">Impersonate
                                    <div className="privileges-ico ico-link" onClick={() => this.changeIcoPrivNew("P_IMPERSONATE")}>{this.icoPriv[newRole.P_IMPERSONATE]}</div>
                                </div>

                                <div className="privileges-list">Restricted Access
                                    <div className="privileges-ico ico-link" onClick={() => this.changeIcoPrivNew("P_RESTRICTED_ACCESS")}>{this.icoPriv[newRole.P_RESTRICTED_ACCESS]}</div>
                                </div>


                                <div className="privileges-list">Portfolio
                                    <div className="privileges-ico ico-link" onClick={() => this.changeIcoPrivNew("P_PORTAFOGLIO")}>{this.icoPriv[newRole.P_PORTAFOGLIO]}</div>
                                </div>


                                <div className="privileges-list title">Mapping</div>
                                
                                <div className="privileges-list">Nielsen Advertisers
                                    <div className="privileges-ico ico-link" onClick={() => this.changeIcoPrivNew("P_MAP_CLIENTI_NIELSEN")}>{this.icoPriv[newRole.P_MAP_CLIENTI_NIELSEN]}</div>
                                </div>

                                <div className="privileges-list">Discovery Advertisers
                                    <div className="privileges-ico ico-link" onClick={() => this.changeIcoPrivNew("P_MAP_CLIENTI_DISCOVERY")}>{this.icoPriv[newRole.P_MAP_CLIENTI_DISCOVERY]}</div>
                                </div>

                                <div className="privileges-list">Arianna Advertisers
                                    <div className="privileges-ico ico-link" onClick={() => this.changeIcoPrivNew("P_MAP_CLIENTI_ARIANNA")}>{this.icoPriv[newRole.P_MAP_CLIENTI_ARIANNA]}</div>
                                </div>

                                <div className="privileges-list">Nielsen Targets
                                    <div className="privileges-ico ico-link" onClick={() => this.changeIcoPrivNew("P_MAP_TARGET_NIELSEN")}>{this.icoPriv[newRole.P_MAP_TARGET_NIELSEN]}</div>
                                </div>

                                <div className="privileges-list">Arianna Targets
                                    <div className="privileges-ico ico-link" onClick={() => this.changeIcoPrivNew("P_MAP_TARGET_ARIANNA")}>{this.icoPriv[newRole.P_MAP_TARGET_ARIANNA]}</div>
                                </div>

                                <div className="privileges-list">Arianna Channels
                                    <div className="privileges-ico ico-link" onClick={() => this.changeIcoPrivNew("P_MAP_CANALI_ARIANNA")}>{this.icoPriv[newRole.P_MAP_CANALI_ARIANNA]}</div>
                                </div>

                                <div className="privileges-list">Discovery Channels
                                    <div className="privileges-ico ico-link" onClick={() => this.changeIcoPrivNew("P_MAP_CANALI_DISCOVERY")}>{this.icoPriv[newRole.P_MAP_CANALI_DISCOVERY]}</div>
                                </div>


                              <div className="privileges-list title">Advertisers / Products</div>
                                <div className="privileges-list">Advertisers management
                                    <div className="privileges-ico ico-link" onClick={() => this.changeIcoPrivNew("P_CLIENTI")}>{this.icoPriv[newRole.P_CLIENTI]}</div>
                                </div>

                                <div className="privileges-list">Nielsen Products management
                                    <div className="privileges-ico ico-link" onClick={() => this.changeIcoPrivNew("P_PRODOTTI_NIELSEN")}>{this.icoPriv[newRole.P_PRODOTTI_NIELSEN]}</div>
                                </div>

                                <div className="privileges-list">Arianna Products management
                                    <div className="privileges-ico ico-link" onClick={() => this.changeIcoPrivNew("P_PRODOTTI_ARIANNA")}>{this.icoPriv[newRole.P_PRODOTTI_ARIANNA]}</div>
                                </div>
                                <div className="privileges-list title">Reports</div>
                                <div className="privileges-list">Discovery 
                                    <div className="privileges-ico ico-link" onClick={() => this.changeIcoPrivNew("P_RPT_DISCOVERY_ANNO_MESE")}>{this.icoPriv[newRole.P_RPT_DISCOVERY_ANNO_MESE]}</div>
                                </div>

                                <div className="privileges-list">Competitors
                                    <div className="privileges-ico ico-link" onClick={() => this.changeIcoPrivNew("P_RPT_COMPETITORS_ANNO")}>{this.icoPriv[newRole.P_RPT_COMPETITORS_ANNO]}</div>
                                </div>

                                <div className="privileges-list title">DasMarianna</div>
                                <div className="privileges-list">General
                                    <div className="privileges-ico ico-link" onClick={() => this.changeIcoPrivNew("P_POL_COMM")}>{this.icoPriv[newRole.P_POL_COMM]}</div>
                                </div>

                                <div className="privileges-list">Import
                                    <div className="privileges-ico ico-link" onClick={() => this.changeIcoPrivNew("P_POL_COMM_IMPORT")}>{this.icoPriv[newRole.P_POL_COMM_IMPORT]}</div>
                                </div>

                                <div className="privileges-list title">Robeek</div>
                                <div className="privileges-list">General
                                    <div className="privileges-ico ico-link" onClick={() => this.changeIcoPrivNew("P_ROOBEEK")}>{this.icoPriv[newRole.P_ROOBEEK]}</div>
                                </div>

                                <div className="privileges-list title">Crm Analyzer</div>
                                <div className="privileges-list">Pipeline Forecast
                                    <div className="privileges-ico ico-link" onClick={() => this.changeIcoPrivNew("P_PIPELINE_FORECAST")}>{this.icoPriv[newRole.P_PIPELINE_FORECAST]}</div>
                                </div>

                                <div className="privileges-list">Pipeline Snapshot
                                    <div className="privileges-ico ico-link" onClick={() => this.changeIcoPrivNew("P_PIPELINE_SNAPSHOT")}>{this.icoPriv[newRole.P_PIPELINE_SNAPSHOT]}</div>
                                </div>

                                <div className="privileges-list">Tracker Brand Solutions
                                    <div className="privileges-ico ico-link" onClick={() => this.changeIcoPrivNew("P_TRACKER_AVAILAB")}>{this.icoPriv[newRole.P_TRACKER_AVAILAB]}</div>
                                </div>

                                <div className="privileges-list">Tracker Pillar
                                    <div className="privileges-ico ico-link" onClick={() => this.changeIcoPrivNew("P_TRACKER_PILLAR")}>{this.icoPriv[newRole.P_TRACKER_PILLAR]}</div>
                                </div>

                                <div className="privileges-list">Overbooking
                                    <div className="privileges-ico ico-link" onClick={() => this.changeIcoPrivNew("P_OVERBOOKING")}>{this.icoPriv[newRole.P_OVERBOOKING]}</div>
                                </div>

                                <div className="privileges-list">Overbooking (advertising pool)
                                    <div className="privileges-ico ico-link" onClick={() => this.changeIcoPrivNew("P_OVERBOOKING_BACINO")}>{this.icoPriv[newRole.P_OVERBOOKING_BACINO]}</div>
                                </div>

                                <div className="privileges-list">Commission Management
                                    <div className="privileges-ico ico-link" onClick={() => this.changeIcoPrivNew("P_PROVV_COMMISSIONS")}>{this.icoPriv[newRole.P_PROVV_COMMISSIONS]}</div>
                                </div>

                                <div className="privileges-list">Commission Statements
                                    <div className="privileges-ico ico-link" onClick={() => this.changeIcoPrivNew("P_PROVV_STATEMENTS")}>{this.icoPriv[newRole.P_PROVV_STATEMENTS]}</div>
                                </div>

                                <div className="privileges-list">Agenda
                                    <div className="privileges-ico ico-link" onClick={() => this.changeIcoPrivNew("P_AGENDA")}>{this.icoPriv[newRole.P_AGENDA]}</div>
                                </div>

                            </div>
                        </div>
                </CardBody>
            </TabPane>
        );

        return tabs;
    }

    changeIcoPriv(colPriv, colIndex){
        let roles = this.state.roles;
        const colValue = roles[colIndex][colPriv];

        if(colValue == "W"){
            roles[colIndex][colPriv] = 'N';
        }else if(colValue == "R"){
            roles[colIndex][colPriv] = 'W';
        }else if(colValue == "N"){
            roles[colIndex][colPriv] = 'R';
        }

        this.setState({ roles: roles});
    }

    changeIcoPrivNew(colPriv){
        let role = this.state.newRole;
        const colValue = role[colPriv];

        if(colValue == "W"){
            role[colPriv] = 'N';
        }else if(colValue == "R"){
            role[colPriv] = 'W';
        }else if(colValue == "N"){
            role[colPriv] = 'R';
        }

        this.setState({ newRole: role});
    }

    async handleSubmitNew(event){
        event.preventDefault();

        Nprogress.start();

        const newRole = this.state.newRole;

        const params = {
            pRoleName:                 this.state.newRoleDescription,
            pPrivLoadNielsenInvGRP   : newRole.P_LOAD_NIELSEN_INV_GRP,
            pPrivLoadNielsenInvShr   : newRole.P_LOAD_NIELSEN_INV_SHR,
            pPrivLoadAriannaGRP      : newRole.P_LOAD_ARIANNA_GRP,
            pPrivLoadDiscoveryAnag   : newRole.P_LOAD_DISCOVERY_ANAG,
            pPrivLoadDiscoveryInv    : newRole.P_LOAD_DISCOVERY_INV,
            pPrivMapClientiNielsen   : newRole.P_MAP_CLIENTI_NIELSEN,
            pPrivMapClientiDiscovery : newRole.P_MAP_CLIENTI_DISCOVERY,
            pPrivMapClientiArianna   : newRole.P_MAP_CLIENTI_ARIANNA,
            pPrivMapTargetNielsen    : newRole.P_MAP_TARGET_NIELSEN,
            pPrivMapTargetArianna    : newRole.P_MAP_TARGET_ARIANNA,
            pPrivMapCanaliArianna    : newRole.P_MAP_CANALI_ARIANNA,
            pPrivMapCanaliDiscovery  : newRole.P_MAP_CANALI_DISCOVERY,
            pPrivClienti             : newRole.P_CLIENTI,
            pPrivAgenzie             : newRole.P_AGENZIE,
            pPrivProdottiNielsen     : newRole.P_PRODOTTI_NIELSEN,
            pPrivProdottiArianna     : newRole.P_PRODOTTI_ARIANNA,
            pPrivProdottiDiscovery   : newRole.P_PRODOTTI_DISCOVERY,
            pPrivCentriMedia         : newRole.P_CENTRI_MEDIA,
            pPrivCanali              : newRole.P_CANALI,
            pPrivTarget              : newRole.P_TARGET,
            pPrivRptDiscoveryAnnoMese: newRole.P_RPT_DISCOVERY_ANNO_MESE,
            pPrivRptCompetitorsAnno  : newRole.P_RPT_COMPETITORS_ANNO,
            pPrivBusinessTypes       : newRole.P_BUSINESS_TYPES,
            pPrivFattoriConversione  : newRole.P_FATTORI_CONVERSIONE,
            pPrivClientiAgenzie      : newRole.P_CLIENTI_AGENZIE,
            pPrivAlerts              : newRole.P_ALERTS,
            pPrivImpersonate         : newRole.P_IMPERSONATE,
            pPrivRestrictedAccess    : newRole.P_RESTRICTED_ACCESS,
            pPrivPolComm             : newRole.P_POL_COMM,
            pPrivPolCommImport       : newRole.P_POL_COMM_IMPORT,
            pPrivPortafoglio         : newRole.P_PORTAFOGLIO,
            pPrivRoobeek             : newRole.P_ROOBEEK,
            pPrivCbContracts         : newRole.P_CB_CONTRACTS,
            pPrivPipelineForecast    : newRole.P_PIPELINE_FORECAST,
            pPrivPipelineSnapshot    : newRole.P_PIPELINE_SNAPSHOT,
            pPrivTrackerAvailab      : newRole.P_TRACKER_AVAILAB,
            pPrivTrackerAvailabPillar: newRole.P_TRACKER_PILLAR,
            pPrivOverbooking         : newRole.P_OVERBOOKING,
            pPrivOverbookingBacino   : newRole.P_OVERBOOKING_BACINO,
            pPrivProvvCommissions    : newRole.P_PROVV_COMMISSIONS,
            pPrivProvvStatements     : newRole.P_PROVV_STATEMENTS,
            pPrivReportLastDates     : newRole.P_REPORT_LAST_DATES,
            pPrivAgenda              : newRole.P_AGENDA
        };

        let responseStatus = 0;
        let responseRetVal = 0;

        await FetchService.post("/usersRole/role-ins", params)
            .then((response) => {
                responseStatus = response.status;
                return response.json();
            }).then((data) => {
                responseRetVal = data;
            }).catch((error) => {
                console.log(error)
            });

        if (responseStatus != 200){
            NotificationWrapper.error(<IntlMessages id="error.global.generic"/>);
            Nprogress.done();
            return;
        }else if(responseRetVal <= 0){
            let errorMessage = "";
            switch (responseRetVal) {
                case 0:
                case -1:
                    errorMessage = <IntlMessages id="error.global.generic"/>;
                    break;
                case -2:
                    errorMessage = <IntlMessages id="error.global.no-privileges"/>;
                    break;
                case -3:
                    errorMessage = <IntlMessages id="error-role-ins-3"/>;
                    break;
                case -4:
                    errorMessage = <IntlMessages id="error-role-ins-4"/>;
                    break;
                case -100:
                    errorMessage = <IntlMessages id="error.global.generic"/>;
                    break;
                case -102:
                    errorMessage = <IntlMessages id="error.global.no-privileges"/>;
                    break;
                case -103:
                    errorMessage = <IntlMessages id="error-role-ins-103"/>;
                    break;
                case -104:
                    errorMessage = <IntlMessages id="error-role-ins-104"/>;
                    break;
                default:
                    errorMessage = <IntlMessages id="error.global.generic"/>;
                    break;
            }
            NotificationWrapper.error(errorMessage);
            Nprogress.done();
            return;
        }

        const newTab = responseRetVal.toString();
        this.getRoles(newTab);

        NotificationWrapper.success(<IntlMessages id="message.global.success"/>);
        Nprogress.done();
    }

    async handleSubmitMod(event){
        event.preventDefault();

        Nprogress.start();

        const roles = this.state.roles;

        let roleIndex = 0;
        for (let i = 0; i < roles.length; i++) {
            if(roles[i]["ROLE_COD"] == this.state.activeTab){
                roleIndex = i;
                break;
            }
        }

        const params = {
            roleCod:                   this.state.activeTab,
            roleName:                  this.state.roleDescriptions[this.state.activeTab],
            pPrivLoadNielsenInvGRP   : roles[roleIndex].P_LOAD_NIELSEN_INV_GRP,
            pPrivLoadNielsenInvShr   : roles[roleIndex].P_LOAD_NIELSEN_INV_SHR,
            pPrivLoadAriannaGRP      : roles[roleIndex].P_LOAD_ARIANNA_GRP,
            pPrivLoadDiscoveryAnag   : roles[roleIndex].P_LOAD_DISCOVERY_ANAG,
            pPrivLoadDiscoveryInv    : roles[roleIndex].P_LOAD_DISCOVERY_INV,
            pPrivMapClientiNielsen   : roles[roleIndex].P_MAP_CLIENTI_NIELSEN,
            pPrivMapClientiDiscovery : roles[roleIndex].P_MAP_CLIENTI_DISCOVERY,
            pPrivMapClientiArianna   : roles[roleIndex].P_MAP_CLIENTI_ARIANNA,
            pPrivMapTargetNielsen    : roles[roleIndex].P_MAP_TARGET_NIELSEN,
            pPrivMapTargetArianna    : roles[roleIndex].P_MAP_TARGET_ARIANNA,
            pPrivMapCanaliArianna    : roles[roleIndex].P_MAP_CANALI_ARIANNA,
            pPrivMapCanaliDiscovery  : roles[roleIndex].P_MAP_CANALI_DISCOVERY,
            pPrivClienti             : roles[roleIndex].P_CLIENTI,
            pPrivAgenzie             : roles[roleIndex].P_AGENZIE,
            pPrivProdottiNielsen     : roles[roleIndex].P_PRODOTTI_NIELSEN,
            pPrivProdottiArianna     : roles[roleIndex].P_PRODOTTI_ARIANNA,
            pPrivProdottiDiscovery   : roles[roleIndex].P_PRODOTTI_DISCOVERY,
            pPrivCentriMedia         : roles[roleIndex].P_CENTRI_MEDIA,
            pPrivCanali              : roles[roleIndex].P_CANALI,
            pPrivTarget              : roles[roleIndex].P_TARGET,
            pPrivRptDiscoveryAnnoMese: roles[roleIndex].P_RPT_DISCOVERY_ANNO_MESE,
            pPrivRptCompetitorsAnno  : roles[roleIndex].P_RPT_COMPETITORS_ANNO,
            pPrivBusinessTypes       : roles[roleIndex].P_BUSINESS_TYPES,
            pPrivFattoriConversione  : roles[roleIndex].P_FATTORI_CONVERSIONE,
            pPrivClientiAgenzie      : roles[roleIndex].P_CLIENTI_AGENZIE,
            pPrivAlerts              : roles[roleIndex].P_ALERTS,
            pPrivImpersonate         : roles[roleIndex].P_IMPERSONATE,
            pPrivRestrictedAccess    : roles[roleIndex].P_RESTRICTED_ACCESS,
            pPrivPolComm             : roles[roleIndex].P_POL_COMM,
            pPrivPolCommImport       : roles[roleIndex].P_POL_COMM_IMPORT,
            pPrivPortafoglio         : roles[roleIndex].P_PORTAFOGLIO,
            pPrivRoobeek             : roles[roleIndex].P_ROOBEEK,
            pPrivCbContracts         : roles[roleIndex].P_CB_CONTRACTS,
            pPrivPipelineForecast    : roles[roleIndex].P_PIPELINE_FORECAST,
            pPrivPipelineSnapshot    : roles[roleIndex].P_PIPELINE_SNAPSHOT,
            pPrivTrackerAvailab      : roles[roleIndex].P_TRACKER_AVAILAB,
            pPrivTrackerAvailabPillar: roles[roleIndex].P_TRACKER_PILLAR,
            pPrivOverbooking         : roles[roleIndex].P_OVERBOOKING,
            pPrivOverbookingBacino   : roles[roleIndex].P_OVERBOOKING_BACINO,
            pPrivProvvCommissions    : roles[roleIndex].P_PROVV_COMMISSIONS,
            pPrivProvvStatements     : roles[roleIndex].P_PROVV_STATEMENTS,
            pPrivReportLastDates     : roles[roleIndex].P_REPORT_LAST_DATES,
            pPrivAgenda              : roles[roleIndex].P_AGENDA
        };

        let responseStatus = 0;
        let responseRetVal = 0;

        await FetchService.post("/usersRole/role-mod", params)
            .then((response) => {
                responseStatus = response.status;
                return response.json();
            }).then((data) => {
                responseRetVal = data;
            }).catch((error) => {
                console.log(error)
            });

        if (responseStatus != 200){
            NotificationWrapper.error(<IntlMessages id="error.global.generic"/>);
            Nprogress.done();
            return;
        }else if(responseRetVal <= 0){
            let errorMessage = "";
            switch (responseRetVal) {
                case 0:
                    errorMessage = <IntlMessages id="error.global.generic"/>;
                    break;
                case -1:
                    errorMessage = <IntlMessages id="error.global.no-privileges"/>;
                    break;
                case -2:
                    errorMessage = <IntlMessages id="error-role-mod-2"/>;
                    break;
                case -3:
                    errorMessage = <IntlMessages id="error-role-mod-3"/>;
                    break;
                case -4:
                    errorMessage = <IntlMessages id="error-role-mod-4"/>;
                    break;
                case -100:
                    errorMessage = <IntlMessages id="error.global.generic"/>;
                    break;
                case -102:
                    errorMessage = <IntlMessages id="error.global.no-privileges"/>;
                    break;
                case -103:
                    errorMessage = <IntlMessages id="error-role-mod-103"/>;
                    break;
                case -104:
                    errorMessage = <IntlMessages id="error-role-mod-104"/>;
                    break;
                default:
                    errorMessage = <IntlMessages id="error.global.generic"/>;
                    break;
            }
            NotificationWrapper.error(errorMessage);
            Nprogress.done();
            return;
        }

        //this.getRoles();
        NotificationWrapper.success(<IntlMessages id="message.global.success"/>);
        Nprogress.done();
    }

    async handleSubmitDel(event){
        event.preventDefault();

        Nprogress.start();

        const roles = this.state.roles;

        const params = {
            roleCod: this.state.activeTab,
        };

        let responseStatus = 0;
        let responseRetVal = 0;

        await FetchService.post("/usersRole/role-del", params)
            .then((response) => {
                responseStatus = response.status;
                return response.json();
            }).then((data) => {
                responseRetVal = data;
            }).catch((error) => {
                // this.props.userSignOut()
                console.log(error)
            });

        if (responseStatus != 200){
            NotificationWrapper.error(<IntlMessages id="error.global.generic"/>);
            Nprogress.done();
            return;
        }else if(responseRetVal <= 0){
            let errorMessage = "";
            switch (responseRetVal) {
                case 0:
                case 1:
                    errorMessage = <IntlMessages id="error.global.generic"/>;
                    break;
                case -2:
                    errorMessage = <IntlMessages id="error.global.no-privileges"/>;
                    break;
                case -3:
                    errorMessage = <IntlMessages id="error-user-mod-priv-3"/>;
                    break;
                case -4:
                    errorMessage = <IntlMessages id="error-user-mod-priv-4"/>;
                    break;
                default:
                    errorMessage = <IntlMessages id="error.global.generic"/>;
                    break;
            }
            NotificationWrapper.error(errorMessage);
            Nprogress.done();
            return;
        }

        this.toggleDelRole();
        this.getRoles();

        NotificationWrapper.success(<IntlMessages id="message.global.success"/>);
        Nprogress.done();
    }

    render() {
        if (this.state.loading) {
            return (
                <div id="role-list">
                </div>
            );
        }else{
            return (
                <div id="role-list">
                    <MuiThemeProvider theme={theme}>
                        <Card className="mt-2 card-roles">
                            <CardHeader className="bg-primary">
                                <Nav className="card-header-tabs" tabs>
                                    {this.createTabsHeader()}
                                </Nav>
                            </CardHeader>
                            <TabContent activeTab={this.state.activeTab}>
                                {this.createTabsBody()}
                            </TabContent>

                        </Card>
                            <div className="col-12 row-btn-submit">
                                <div className="form-group mb-0">
                                    <Button variant="contained" color="primary" className={this.state.activeTab !== "new" ? "jr-btn" : "jr-btn hidden"} type="submit" onClick={this.handleSubmitMod.bind(this)}>Save</Button>
                                    <Button variant="contained" color="primary" className={this.state.activeTab === "new" ? "jr-btn" : "jr-btn hidden"} type="submit" onClick={this.handleSubmitNew.bind(this)}>New role</Button>
                                    <Button variant="contained" color="secondary" className={this.state.activeTab !== "new" ? "jr-btn" : "jr-btn hidden"} type="submit" onClick={this.toggleDelRole}>Delete</Button>
                                </div>
                            </div>
                        <Modal isOpen={this.state.modalNewRole} toggle={this.toggleDelRole} className="modal-delete-confirm">
                            <ModalHeader>Delete Role</ModalHeader>
                            <ModalBody>
                                <IntlMessages id="settings.role.delete.confirm"/><br/>
                            </ModalBody>
                            <ModalFooter>
                                <Button variant="contained" color="primary" onClick={this.handleSubmitDel.bind(this)}>Delete</Button>{' '}
                                <Button variant="contained" onClick={this.toggleDelRole}>Cancel</Button>
                            </ModalFooter>
                        </Modal>
                    </MuiThemeProvider>
                </div>
            );
        }
    }
}

export default connect(null)(RoleList);
