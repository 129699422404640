import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import asyncComponent from '../../../util/asyncComponent';

const Tracking = ({ match }) => {
    
    return (
        <div className="app-wrapper">
            <Switch>
                {/* Reindirizzamento dalla radice "tracker" a brandsolutions, gestendo anche lo slash finale */}
                <Route exact path={[`${match.url}`, `${match.url}/`]}>
                    <Redirect to={`${match.url.replace(/\/$/, '')}/brandsolutions`} />
                </Route>
                
                {/* Rotta per brandsolutions e pillar */}
                <Route
                    path      ={[`${match.url}/brandsolutions`, `${match.url}/pillar`]}
                    component ={asyncComponent(() => import('./routes/brandsolutions'))}
                />
                
                {/* Rotta di fallback per 404 */}
                <Route
                    component ={asyncComponent(() => import('app/routes/extraPages/routes/404'))}
                />
            </Switch>
        </div>
    );
};

export default Tracking;
