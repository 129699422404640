import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import asyncComponent from '../../../util/asyncComponent';
import Error404 from '../extraPages/routes/404';

const Options = ({match}) => {
    
    const privileges = JSON.parse(localStorage.getItem('privileges'));

    return(
        <React.Fragment>
        {privileges.FLAG_ADMIN!=='Y' ? 
            <Error404/>
        :
            <div className="app-wrapper">
                <Switch>          
                    <Redirect exact from={`${match.url}/`} to={`${match.url}/advertisers`}/>
                    <Route path={`${match.url}/advertisers/`} component={asyncComponent(() => import('./routes/advertisers'))}/>
                    <Route path={`${match.url}/deliveries/`} component={asyncComponent(() => import('./routes/deliveries'))}/>
                    <Route path={`${match.url}/channels/`} component={asyncComponent(() => import('./routes/channels'))}/>
                    <Route path={`${match.url}/proposals/`} component={asyncComponent(() => import('./routes/proposals'))}/>
                    <Route path={`${match.url}/mapping/`} component={asyncComponent(() => import('./routes/mapping'))}/>
                    <Route path={`${match.url}/kubik-audiences/`} component={asyncComponent(() => import('./routes/kubikAudiences'))}/>
                    <Route component={asyncComponent(() => import('app/routes/extraPages/routes/404'))}/>
                </Switch>
            </div>
        }
        </React.Fragment>
    );
}

export default Options;