/* eslint-disable quotes */
import React from 'react';
import "./Header.css";
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {Dropdown, DropdownMenu, DropdownToggle} from 'reactstrap';
import {COLLAPSED_DRAWER, FIXED_DRAWER, HORIZONTAL_NAVIGATION, INSIDE_THE_HEADER} from 'constants/ActionTypes';
import SearchBox from 'components/SearchBox';
import {switchLanguage, toggleCollapsedNav} from 'actions/Setting';
import UserInfo from 'components/UserInfo';
import Menu from "components/Header/Menu";
import {MuiThemeProvider, createMuiTheme, withStyles} from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import { history } from 'store';
import { Link } from 'react-router-dom';

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#2789d8',
        },
    },
    typography: {
        useNextVariants: true,
        fontSize: 11,
    }
});

const AntSwitch = withStyles(theme => ({
    root: {
        width: 28,
        height: 16,
        padding: 0,
        display: 'flex',
    },
    switchBase: {
        padding: 2,
        color: theme.palette.primary.main,
        '&$checked': {
            transform: 'translateX(12px)',
            color: theme.palette.primary.main,
            '& + $track': {
                opacity: 1,
                backgroundColor: theme.palette.common.white,
                borderColor: theme.palette.common.white,
            },
        },
    },
    thumb: {
        width: 12,
        height: 12,
        boxShadow: 'none',
    },
    track: {
        color: theme.palette.primary.main,
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor: theme.palette.common.white,
        borderColor: theme.palette.common.white,
    },
    checked: {},
}))(Switch);

class Header extends React.Component {

    constructor() {
        super();

        const internalUserMode = localStorage.getItem("internalUserMode") == 'Y' ? true : false;
        const flagDoppioProfilo = localStorage.getItem("flagDoppioProfilo") == 'Y' ? true : false;
        const name = localStorage.getItem("name");
        const surname = localStorage.getItem("surname");
        const username = name.length > 1 ? `${name.substr(0, 1)}. ${surname}` : `${name.substr(0, 1)} ${surname}`;
        const restrictedMode = localStorage.getItem("restrictedMode") != undefined ? localStorage.getItem("restrictedMode") : 'N';

        this.state = {
            searchBox: false,
            searchText: '',
            mailNotification: false,
            langSwitcher: false,
            appNotification: false,
            userInfo: false,
            username: username,
            internalUserMode: internalUserMode,
            flagDoppioProfilo: flagDoppioProfilo,
            restrictedMode: restrictedMode,
        }
    }

    componentWillReceiveProps(props) {

        const internalUserMode = localStorage.getItem("internalUserMode") == 'Y' ? true : false;
        const flagDoppioProfilo = localStorage.getItem("flagDoppioProfilo") == 'Y' ? true : false;
        const name = localStorage.getItem("name");
        const surname = localStorage.getItem("surname");
        const username = name.length > 1 ? `${name.substr(0, 1)}. ${surname}` : `${name.substr(0, 1)} ${surname}`;
        const restrictedMode = localStorage.getItem("restrictedMode") != undefined ? localStorage.getItem("restrictedMode") : 'N';
        // TODO this.setState(
        this.state = {
            username: username,
            internalUserMode: internalUserMode,
            flagDoppioProfilo: flagDoppioProfilo,
            restrictedMode: restrictedMode,
        }
    }


    onAppNotificationSelect = () => {
        this.setState({
            appNotification: !this.state.appNotification
        })
    };
    onMailNotificationSelect = () => {
        this.setState({
            mailNotification: !this.state.mailNotification
        })
    };

    onUserInfoSelect = () => {
        this.setState({
            userInfo: !this.state.userInfo
        })
    };

    onLangSwitcherSelect = (event) => {
        this.setState({
            langSwitcher: !this.state.langSwitcher
        })
    };
    onSearchBoxSelect = () => {
        this.setState({
            searchBox: !this.state.searchBox
        })
    };
    handleRequestClose = () => {
        this.setState({langSwitcher: false, mailNotification: false, appNotification: false, searchBox: false});
    };
    onToggleCollapsedNav = (e) => {
        const val = !this.props.navCollapsed;
        this.props.toggleCollapsedNav(val);
    };

    updateSearchText(evt) {
        this.setState({
            searchText: evt.target.value,
        });
    }

    handleCloseQuickMenu(){
        this.setState({
            userInfo: false,
        });
    }

    async handleChangeInternalUserMode(event){
        await this.setState({ internalUserMode : !this.state.internalUserMode });
        const internalUserMode = this.state.internalUserMode ? 'Y' : 'N';
        localStorage.setItem('internalUserMode', internalUserMode);
        history.push(history.location.pathname);
      
    }

    getSectionTitle = () => {
        const privileges = JSON.parse(localStorage.getItem('privileges'));
        if(history.location.pathname.indexOf('pipeline/forecast/1') >= 0){
            return 'Pipeline | Forecast (Linear)';
        }else if(history.location.pathname.indexOf('pipeline/forecast/2') >= 0){
            return 'Pipeline | Forecast (Digital)';
        }else if(history.location.pathname.indexOf('pipeline/forecast/deleted') >= 0){
            return 'Pipeline | Deleted Forecast';
        }else if(history.location.pathname.indexOf('tracker/brandsolutions') >= 0){
            return 'Tracker | Brand Solutions';
        }else if(history.location.pathname.indexOf('tracker/pillar') >= 0){
            return 'Tracker | Pillar';
        }else if(history.location.pathname.indexOf('overbooking/overbooking') >= 0){
            return 'Overbooking';
        }else if(history.location.pathname.indexOf('options/channels') >= 0 && privileges.FLAG_ADMIN === 'Y'){
            return 'Global Settings | Channels';
        }else if(history.location.pathname.indexOf('options/deliveries') >= 0 && privileges.FLAG_ADMIN === 'Y'){
            return 'Global Settings | Deliveries';    
        }else if(history.location.pathname.indexOf('dashboard') >= 0){
            return 'CRM Analyzer';
        }else if(history.location.pathname.indexOf('app/usersList') >= 0){
            return 'Global Settings | Users | User List ';
        } else if (history.location.pathname.indexOf('/app/users/account-details/') >= 0) {
            return <React.Fragment>
            Global Settings | Users | <Link to="/app/usersList" className='LinkHeaderName'>User List</Link> | User Management
                 </React.Fragment>
        } else if(history.location.pathname.indexOf('/app/usersRoles') >= 0){
            return 'Global Settings | Users | Roles ';
        }else if(history.location.pathname.indexOf('/app/UserLoginHistory') >= 0){
            return 'Global Settings | Users | Login history';
        }else if(history.location.pathname.indexOf('/app/alert') >= 0){
            return 'Alverman | Alert';
        }else if(history.location.pathname.indexOf('/app/mapping') >= 0){
            return 'Alverman | Mapping';
        }else if(history.location.pathname.indexOf('/app/settings/profile/impersonate-user') >= 0){
            return 'Impersonate user';
        }else if(history.location.pathname.indexOf('/app/settings/profile/change-password') >= 0){
            return 'Change password';
        }else if(history.location.pathname.indexOf('/app/commissions/settings') >= 0){
            return 'Commission management';
        }else if(history.location.pathname.indexOf('/app/commissions/portfolio-variations') >= 0){
            return 'Portfolio Variations';   
        }else if(history.location.pathname.indexOf('/app/commissions/sales-commission-statements') >= 0){
            return 'Sales Commission Statements';               
        }else if(history.location.pathname.indexOf('/app/commissions/list') >= 0){
            return 'Sales Commissions';    
        }else if(history.location.pathname.indexOf('/app/commissions/sales-commission-summary') >= 0){
            return 'Sales Commission Summary';                               
        }else if(history.location.pathname.indexOf('/app/options/advertisers') >= 0 && privileges.FLAG_ADMIN === 'Y'){
            return 'Analysis Targets';
        }else if(history.location.pathname.indexOf('/app/options/proposals') >= 0 && privileges.FLAG_ADMIN === 'Y'){
            return 'Proposals';
        }else if(history.location.pathname.indexOf('/app/options/mapping') >= 0 && privileges.FLAG_ADMIN === 'Y'){
            return 'Global Settings | Mapping';
        }else if(history.location.pathname.indexOf('/app/portfolio/portfolio-actual') >= 0){
            return 'Portfolio | Actual';
        }else if(history.location.pathname.indexOf('/app/options/kubik-audiences') >= 0 && privileges.FLAG_ADMIN === 'Y'){
            return 'Global Settings | Kubik Audiences';
        }else if(history.location.pathname.indexOf('/app/agenda/meeting-report') >= 0 && ((privileges.FLAG_ADMIN === 'Y') || (privileges.P_AGENDA >= 'R'))){
            return 'Agenda | Meeting Report';
        }else if(history.location.pathname.indexOf('/app/agenda/meeting-calendar') >= 0 && ((privileges.FLAG_ADMIN === 'Y') || (privileges.P_AGENDA >= 'R'))){
            return 'Agenda | Meeting Calendar';
        }else if(history.location.pathname.indexOf('/app/agenda/contacts') >= 0 && ((privileges.FLAG_ADMIN === 'Y') || (privileges.P_AGENDA >= 'R'))){
            return 'Agenda | Contacts';
        }else if(history.location.pathname.indexOf('/app/agenda/roles') >= 0 && privileges.FLAG_ADMIN === 'Y'){
            return 'Agenda | Roles';
        }else if(history.location.pathname.indexOf('/app/agenda/meeting-reasons') >= 0 && privileges.FLAG_ADMIN === 'Y'){
            return 'Agenda | Meeting Reasons';
        }else{
            return '';
        }
    }

    render() {
        const {username, flagDoppioProfilo, internalUserMode, restrictedMode} = this.state;
        const {drawerType, locale, navigationStyle, horizontalNavPosition} = this.props;
        const drawerStyle = drawerType.includes(FIXED_DRAWER) ? 'd-flex d-xl-none' : drawerType.includes(COLLAPSED_DRAWER) ? 'd-flex' : 'd-none';

        return (
            <div className="app-main-header">
                <div className={restrictedMode == 'Y' ? "restricted" : ""}>
                    <div className="d-flex app-toolbar align-items-center">
                        {navigationStyle === HORIZONTAL_NAVIGATION  ?
                            <div className="app-logo-bl">
                                <div className="d-block d-md-none">
                                    <span className="jr-menu-icon"
                                          onClick={this.onToggleCollapsedNav}>
                                        <span className="menu-icon"/>
                                    </span>
                                </div>
                                <div className="app-logo pointer d-none d-md-block">
                                    <img className="d-none d-lg-block" alt='...' src='http://via.placeholder.com/105x36'/>
                                    <img className="d-block d-lg-none mr-3" alt='...'
                                         src='http://via.placeholder.com/32x32'/>
                                </div>
                            </div>
                            :
                            <span className={`jr-menu-icon pointer ${drawerStyle}`}
                                  onClick={this.onToggleCollapsedNav}>
                                <span className="menu-icon"/>
                            </span>
                        }

                        <div className="section-title">
                            { this.getSectionTitle() }
                        </div>

                        <SearchBox styleName="d-none d-lg-block"
                                   onChange={this.updateSearchText.bind(this)}
                                   value={this.state.searchText}/>

                        {restrictedMode == 'Y' ? <div className="restricted-label">RESTRICTED ACCESS</div> : ""}

                        {(navigationStyle === HORIZONTAL_NAVIGATION && horizontalNavPosition === INSIDE_THE_HEADER) &&
                        <Menu/>}

                        <ul className="header-notifications list-inline ml-auto">
                            <li className="list-inline-item user-nav" style={{display: 'none', paddingRight: 0, paddingLeft: 0, fontSize: 14}}>
                                <div className={flagDoppioProfilo ? "switch-internal-user-mode" : "switch-internal-user-mode-hide"}>
                                    <MuiThemeProvider theme={theme}>
                                        <Grid component="label" container alignItems="center" spacing={1} style={{width: 120}}>
                                            <Grid item>Portfolio</Grid>
                                            <Grid item style={{padding: 0}}>
                                                <AntSwitch
                                                    checked={internalUserMode}
                                                    onChange={() => this.handleChangeInternalUserMode()}
                                                />
                                            </Grid>
                                            <Grid item>All</Grid>
                                        </Grid>
                                    </MuiThemeProvider>
                                </div>
                            </li>

                            <li className="list-inline-item user-nav" style={{paddingRight: 0, marginRight: 0}}>
                                <span style={{fontSize: 14}}>{username}</span>
                            </li>

                            <li className="list-inline-item user-nav">
                                <Dropdown
                                    className="quick-menu"
                                    isOpen={this.state.userInfo}
                                    toggle={this.onUserInfoSelect.bind(this)}>

                                    <DropdownToggle
                                        className="d-inline-block"
                                        tag="span"
                                        data-toggle="dropdown">
                                        <img
                                            alt='...'
                                            src='assets/images/user-placeholder.jpg'
                                            className="pointer user-avatar size-40"/>
                                    </DropdownToggle>

                                    <DropdownMenu right>
                                        <UserInfo handleCloseQuickMenu={this.handleCloseQuickMenu.bind(this)} />
                                    </DropdownMenu>
                                </Dropdown>

                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        );
    }

}

const mapStateToProps = ({settings}) => {
    const {drawerType, locale, navigationStyle, horizontalNavPosition} = settings;
    return {drawerType, locale, navigationStyle, horizontalNavPosition}
};

export default withRouter(connect(mapStateToProps, {toggleCollapsedNav, switchLanguage})(Header));
