import moment from 'moment';
let timeoutId = null; // questa variabile deve essere globale al caricamento di questo modulo

export default class CommonUtilities {

    static getFormatDateFromDate(inputDate, separatorChar = '/'){
        //torna una stringa in formato dd/mm/yyyy a partire da un Date
        //separatore di default è / però indicarne uno diverso per i file generati siccome / è riservato
        let month = '' + (inputDate.getMonth() + 1);
        let day = '' + inputDate.getDate();
        let year = inputDate.getFullYear();
    
        if (month.length < 2)month = '0' + month;
        if (day.length < 2)day = '0' + day;
    
        return [day, month, year].join(separatorChar);
    }

    static getFormatDate(inputDate) {
        //torna una stringa in formato dd/mm/yyyy a partire da una stringa formato 'YYYYMMDD...'

        let retVal = '';

        if(inputDate !== null){
            try {
                const yyyy = inputDate.substring(0, 4);
                const mm = inputDate.substring(4, 6);
                const dd = inputDate.substring(6, 8);

                retVal = `${dd}/${mm}/${yyyy}`;

            }catch (err) {
                console.log('CommonUtilities - getFormatDate' + err.message);
            }
        }

        return retVal;
    }

    static getFormatDateTime(inputDate) {
        //torna una stringa in formato dd/mm/yyyy hh24:mi:ss a partire da una stringa formato 'YYYYMMDDHH24MISS'

        let retVal = '';

        if(!inputDate) { return retVal; }
        
        try {
            const yyyy = inputDate.substring(0, 4);
            const mm = inputDate.substring(4, 6);
            const dd = inputDate.substring(6, 8);

            let hh= '';
            let mi= '';
            let ss= '';
            hh = inputDate.substring(8, 10);
            mi = inputDate.substring(10, 12);
            ss = inputDate.substring(12, 14);

            retVal = `${dd}/${mm}/${yyyy} ${hh}:${mi}:${ss}`;
            
        }catch (err) {
            console.log('CommonUtilities - getFormatDateTime' + err.message);
        }

        return retVal;
    }

    static getStringFromDateString(inputDate) {
        //torna una stringa in formato 'YYYYMMDD' a partire da una stringa formato 'DD/MM/YYYY'

        let retVal = '';

        try {
            const yyyy = inputDate.substring(6, 10);
            const mm = inputDate.substring(3, 5);
            const dd = inputDate.substring(0, 2);

            retVal = `${yyyy}${mm}${dd}`;

        }catch (err) {
            console.log('CommonUtilities - getStringFromDateString ' + err.message);
        }

        return retVal;
    }

    static getStringFromDate(inputDate) {
        //torna una stringa formato 'YYYYMMDD'  a partire da un Date

        let retVal = '';

        try {
            if ( moment( inputDate ).isValid() ) {
                const d = inputDate.getDate().toString().padStart(2, '0');
                const m = (inputDate.getMonth() + 1).toString().padStart(2, '0');
                const y = inputDate.getFullYear().toString().padStart(4, '0');
                retVal = `${y}${m}${d}`;
            }
        }catch (err) {
            console.log('CommonUtilities - getStringFromDate ' + err.message);
        }

        return retVal;
    }

    static getStringDateTimeFromDate(inputDate) {
        //torna una stringa formato 'YYYYMMDDhhmm'  a partire da un Date

        let retVal = '';

        try {
            if ( moment( inputDate ).isValid() ) {
                const d = inputDate.getDate().toString().padStart(2, '0');
                const m = (inputDate.getMonth() + 1).toString().padStart(2, '0');
                const y = inputDate.getFullYear().toString().padStart(4, '0');
                const h = inputDate.getHours().toString().padStart(2, '0');
                const mm = inputDate.getMinutes().toString().padStart(2, '0');
                retVal = `${y}${m}${d}${h}${mm}`;
            }
        }catch (err) {
            console.log('CommonUtilities - getStringFromDate ' + err.message);
        }

        return retVal;
    }

    static getDateFromString(inputDate) {
        //torna un Date a partire da una stringa formato 'YYYYMMDD'

        let retVal = null;

        try {
            retVal = new Date(parseInt(inputDate.substring(0, 4)), parseInt(inputDate.substring(4, 6)) - 1, parseInt(inputDate.substring(6, 8)));
        }catch (err) {
            console.log('CommonUtilities - getDateFromString' + err.message);
        }

        return retVal;
    }

    static getStringFromTime(inputTime) {
        //torna una stringa formato 'HH24MI' a partire da un DateTime

        let retVal = '';

        try {
            const hh = inputTime.getHours().toString().padStart(2, '0');
            const mi = inputTime.getMinutes().toString().padStart(2, '0');
            retVal = `${hh}${mi}`;

        }catch (err) {
            console.log('CommonUtilities - getStringFromTime' + err.message);
        }

        return retVal;
    }

    static getTimeFromString(inputTime) {
        //torna un Date per la parte time (hh:mi) a partire da una stringa formato 'HH24MI'

        let retVal = null;

        try {
            let today = new Date();
            today.setHours(inputTime.substring(0, 2));
            today.setMinutes(inputTime.substring(2, 4));
            retVal = today;
        }catch (err) {
            console.log('CommonUtilities - getTimeFromString' + err.message);
        }

        return retVal;
    }


    static isFloatKey(evt) {
        //controlla l'inserimento di caratteri validi per numeri con decimali (','). Ok anche negativi ('-')
        const key = evt.key;
        //passano solo i seguenti caratteri di controllo
        if (key === 'Tab' || key === 'Backspace' || key === 'Delete' || key === 'ArrowLeft' || key === 'ArrowRight' || key === 'Home' || key === 'End') {
            return true;
        }else{
            //passano solo caratteri dei numeri (0..9), il meno ('-') e la virgola (',')
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if(charCode == 188 ||charCode == 189 || (charCode >= 48 && charCode <= 57)|| (charCode >= 96 && charCode <= 105)){
                return true;
            }else{
                return false;
            }
        }
    }

    static formatFloat(num, withZero = false) {
         //visualizza una stringa numerica mettendo la virgola come separatore dei decimali
        let str = '';

        if(num != null && num.toString().trim() != ''){

            if(withZero && num == 0){
                return '0';
            }


            const f =  +(Math.round(num + 'e+2')  + 'e-2');
            str = f.toString();
            str = str.replace(/\./g, ',');
        }

        return str;
    }

    // vengono aggiunti i punti come separatori delle migliaia
    // anche se viene passato un numero con decimali viene presa in considerazione e restituita solo la parte intera
    static formatPositiveIntNum = numero => {
        let stringNum = '', numParsed = '' ;
        if ( numero != null ) {

            stringNum       = numero.toString();            // converto in stringa per usare split()
            let [ strInt ]  = stringNum.split('.');         // prendo solo il primo elemento dell'array (la parte intera)
            numParsed       = parseInt(strInt);             // eseguo il parseInt()

            if ( !isNaN(numParsed) && numParsed >= 0 ) {    // se il risultato è NaN oppure un numero negativo esco
                let numAsString = numParsed.toString();     // altrimenti riconverto in stringa il numero, per controllare la lunqhezza e usare replace()
                if ( numAsString.length > 3 ) {             // aggiungo il separatore delle migliaia - ogni 3 numeri sulla parte intera
                    numAsString = numAsString.replace(/\B(?=(\d{3})+(?!\d))/g, '.'); // oppure '˙'
                }
                return numAsString;
            }

        }
        return '';
    }

    static formatIntNum = (numero, withZero=false) => {
        let stringNum = '', numParsed = '' ;
        if ( numero != null ) {

            stringNum       = numero.toString();            // converto in stringa per usare split()
            let [ strInt ]  = stringNum.split('.');         // prendo solo il primo elemento dell'array (la parte intera)
            numParsed       = parseInt(strInt);             // eseguo il parseInt()

            if (!isNaN(numParsed)) {    // se il risultato è NaN oppure un numero negativo esco
                let numAsString = numParsed.toString();     // altrimenti riconverto in stringa il numero, per controllare la lunqhezza e usare replace()
                if ( numAsString.length > 3 ) {             // aggiungo il separatore delle migliaia - ogni 3 numeri sulla parte intera
                    numAsString = numAsString.replace(/\B(?=(\d{3})+(?!\d))/g, '.'); // oppure '˙'
                }
                return numAsString;
            }
        }
        if(withZero){
            return 0;
        }else{
           return '';
        }
    }


    static formatFloatNum(num, minimumFractionDigits=2, maximumFractionDigits=2, suffix='') {
       let str = '';

       if(num != null && num.toString().trim() != ''){
            str = num.toLocaleString('it-IT', { minimumFractionDigits, maximumFractionDigits }) + suffix;
       }

       return str;
   }

   
    static formatFloatToDB(strIn) {
        //formatta una stringa numerica mettendo il punto come separatore dei decimali
        var str = '';
        if(strIn != null){
            str = strIn.toString().trim();
            if(str != ''){
                str = str.replace(/,/g, '.')
            }
        }
        return str;
    }

    static getISOWeekNumber(dt)
    {
        var tdt = new Date(dt.valueOf());
        var dayn = (dt.getDay() + 6) % 7;
        tdt.setDate(tdt.getDate() - dayn + 3);
        var firstThursday = tdt.valueOf();
        tdt.setMonth(0, 1);
        if (tdt.getDay() !== 4)
        {
            tdt.setMonth(0, 1 + ((4 - tdt.getDay()) + 7) % 7);
        }
        return 1 + Math.ceil((firstThursday - tdt) / 604800000);
    }

    static getIntNumber(num)
    {
        let myNum = 0;

        if(num !== undefined && num !== null && num !== ''){
            myNum = parseInt(num.toString().replace(/[.+e]/g,''));
        }else{
            myNum = '';
        }

        return myNum;
    }

    static getVoid(value)
    {
        if(isNaN(value) || value === null || value === undefined || value === 'null'){
            return '';
        }else{
            return value;
        }
    }

    static isDateValid          = (oDate) => moment( oDate ).isValid();
    static getWeekDay           = (oDate) => moment( oDate ).isValid() ? moment(oDate).format('ddd') : '';
    static fromDateToString     = (d) => {
        const mDate =  moment(d);
        return mDate.isValid() ? mDate.format('YYYYMMDD') : '';
    }
    static fromStringToDate     = (s) => {
        const mDate = moment(s, 'YYYYMMDD');
        return mDate.isValid() ? mDate.toDate() : null
    };
    static addOrSubMonths       = ( sDate, nMonths ) =>
        moment( sDate, 'YYYYMMDD' )[ nMonths > 0 ? 'add' : 'subtract' ]( nMonths > 0 ? nMonths : -nMonths, 'M' ).toDate();
    static isStringDateValid    = (sDate) => moment( sDate, 'YYYYMMDD' ).isValid();
    
    /**
     * Verifica la validità di due date in formato stringa.
     *
     * @param {Object} params                   - Oggetto contenente le date da verificare e l'opzione settimanale
     * @param {string} params.pStartDate        - Data di inizio in formato 'YYYYMMDD'
     * @param {string} params.pEndDate          - Data di fine in formato 'YYYYMMDD'
     * @param {boolean} [isWeekly=true]         - Se true, verifica che le date siano domenica (inizio) e sabato (fine)
     * @param {number} [nMaxMonths=6]           - Numero massimo di mesi consentiti tra le date (default 6)
     * @returns {boolean} Restituisce true solo se le date sono valide e rispettano tutti i criteri
    **/
    static areStringDatesValid = ({ pStartDate, pEndDate }, isWeekly = true, nMaxMonths = 6 ) => {
        const
            // Converte le stringhe di date in oggetti moment
             mStartDate             = moment(pStartDate, 'YYYYMMDD')
            ,mEndDate               = moment(pEndDate, 'YYYYMMDD')
        
            // Verifica di base per la validità delle date
            ,areDatesValid          = mStartDate.isValid() && mEndDate.isValid()
        
            // Verifica della condizione settimanale se isWeekly è true
            ,isWeeklyConditionMet   = isWeekly ? ( ( mStartDate.day() === 0 ) && ( mEndDate.day() === 6 ) ) : true
        
            // Verifica della differenza massima di mesi
            ,isWithinMaxMonths      = mStartDate.diff( mEndDate, 'months' ) <= nMaxMonths
        ;
        return areDatesValid && isWeeklyConditionMet && isWithinMaxMonths;
    }

    static firstUpperCase       = (s) => s[0].toUpperCase() + s.slice(1)
    static dashPad              = (numTrattiniIni, stringa, numTot) => {
        let str= stringa.toString();
        let repeatTrattino= (numTot-(numTrattiniIni+str.length+2));
        if (!Number.isInteger(numTot) || !Number.isInteger(numTrattiniIni)) {
            return str
        }
        if (repeatTrattino<0) {
            repeatTrattino=0
        }

        return `${('-').repeat(numTrattiniIni)} ${str} ${('-').repeat(repeatTrattino)}`
    }

    static scrollToID           = (sID) => {
        if ( sID && ( sID !== '|' ) && ( sID !== '||' ) && document.getElementById(sID) ) {
            // const yOffset = +10;
            // const element = document.getElementById(sID);
            // const y = element.getBoundingClientRect().top + window.scrollY + yOffset;
            // window.scrollTo({ top: y, behavior: 'smooth' });
            document.getElementById(sID).scrollIntoView({ block: 'end' });
            // window.scrollBy(0, -200);
        }
    }

    static setAsyncTimeout( callbackFn, timeout = 0) {
        return new Promise(resolve => {
            timeoutId = setTimeout(
                () => {
                timeoutId = null;
                callbackFn();
                resolve();
            }
            , timeout
            );
        });
    }

    static asyncDebounce(fn, time) {

        async function wrapper(...args) {

            if ( timeoutId ) {
                clearTimeout(timeoutId);
            }

            function setAsyncTimeout( callbackFn, parameters, timeout = 0) {

                return new Promise(resolve => {
                    timeoutId = setTimeout(
                        async () => {
                            timeoutId = null;
                            const response = await callbackFn(...parameters);
                            resolve(response);
                        }
                        , timeout
                    );
                });
            }

            return await setAsyncTimeout( fn, args, time );

        }

        return wrapper;

    }

    static applyFlagSelectedFromTo ( aoFrom, aoTo ) {

        const aoOrigin      = Array.isArray(aoFrom) ? [...aoFrom] : [];
        const aoDestination = Array.isArray(aoTo  ) ? [...aoTo  ] : [];

        for ( const oDestination of aoDestination ) { // ripristino i flag di selezione applicandoli al nuovo elenco di elementi
            oDestination.FLAG_SELECTED = ( ( aoOrigin || [] ).find( o => o.COD+'' === oDestination.COD+'' ) || {} ).FLAG_SELECTED || 'N';
        }
        
        return aoDestination;
    }

    static debounce(fn, time) {

        function wrapper(...args) {

            if ( timeoutId ) {
                clearTimeout(timeoutId);
            }

            timeoutId = setTimeout(
                () => {
                    timeoutId = null;
                    fn(...args);
                }, time
            );

        }

        return wrapper;

    }

    static formatNumbersIt(inputValue){
        if(inputValue===undefined)return '';
        return new Intl.NumberFormat('it-IT').format(inputValue);
    }

    static formatNumbersItDecimals(inputValue){
        if(inputValue===undefined)return '';
        return new Intl.NumberFormat('it-IT',{minimumFractionDigits:8}).format(inputValue);
    }
    
    static creaLista = (ao) => {
        if ( !ao || !Array.isArray(ao) || ( ao.length < 1 ) ) return '';
        const aoResults = [];
        for ( const o of ao ) {
            if ( o.FLAG_SELECTED === 'Y' ) {
                aoResults.push(o.COD);
            }
        }
        return aoResults.join(','); // molto più efficiente concatenare alla fine in una stringa, anziché concatenare ad ogni ciclo (meno RAM)
    }

}
