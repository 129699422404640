
import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Link } from "react-router-dom";
import { Badge } from "reactstrap";
import IntlMessages from "util/IntlMessages";
import CardBox from "../../../../components/CardBox";
import PrivilegeList from "./privilegeList"
import {MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Input from '@material-ui/core/Input';
import Switch from '@material-ui/core/Switch';
import Select from '@material-ui/core/Select';
import Nprogress from "nprogress";
import MenuItem from '@material-ui/core/MenuItem';
import NotificationWrapper from "util/NotificationWrapper";
import Autocomplete from '@mui/material/Autocomplete';
import FetchService from 'services/FetchService';
import {Typeahead} from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { Checkbox } from "@material-ui/core";
import { createTheme } from '@material-ui/core/styles';

import './UserRoleList.css'
import "./accountDetail.css";


const theme = createTheme({
    palette: {
        primary: {
            main: '#2789d8',
        },
    },
    typography: {
        useNextVariants: true,
        fontSize: 13,
    }
});



export class UserDetails extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            userId: "",
            username: "",
            nome: "",
            cognome: "",
            password: "",
            confirmPassword: "",
            eMailAddress: "",
            forcePassword: "",
            confirmForcePassword: '',
            showPassword: false,
            showConfirmPassword: false,
            showForcePassword: false,
            showConfirmForcePassword: false,
            flagAdmin: false,
            flagAttivo: false,
            toggleEnabled: false,
            flagDoppioProfilo: false,
            admin: false,
            dataUltimaLogin: "",
            dataCancellazione: '',
            dataInserimento: '',
            dataUltimaModifica: '',
            refreshPrivilegesList: false,
            loading: true,
            modalDelUser: false,
            listaAgenteCod: [],
            kUser: this.props.kUser,
            roles: [],
            roleCod: "",
            isModalOpen: false,
            modalForcePassword: false,
            modalDelUser: false,
            linkEdit: false,
            linkEditId: "",
            modalNewRole: false,
            childComponentSave: false,
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleChangeCheck = this.handleChangeCheck.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.userRoleListChangeHandler = this.userRoleListChangeHandler.bind(this);

        this.toggleDelRole = this.toggleDelRole.bind(this);
    }

    
    toggleDelRole() {
        this.setState({
            modalNewRole: !this.state.modalNewRole
        });
    }
    async componentDidMount() {
        const kUser = this.props.match.params.kuser;

        this.loadUser();
        /*
        if(kUser == "new") {
            //new user
            this.setState({
                userId: "new",
                loading: false
            });
        }else {
            //edit user
            this.loadUser();
        }
        */
        
    }

    async  getUserRoles(){
        const params = {kUser: this.props.match.params.kuser};

        FetchService.get("/users/user-get-roles", params)
            .then((data) => {
                const roleCodArray = data.filter(item => item.FLAG_GRANTED === 'Y').map(item => item.ROLE_COD);
                this.setState({ roles: data, loading : false, roleCod: roleCodArray});
            })
            .catch((error) => {
                console.log(error)
            });

            
    }


    loadUser(){
        let params = {kUser: this.props.match.params.kuser};

        FetchService.get("/users/user-get", params)
            .then((data) => {
                if (data != null) {
                    let listaAgenteCod = [];
                    if(data.LISTA_AGENTE_COD != null && data.LISTA_AGENTE_COD != "") {
                        listaAgenteCod = data.LISTA_AGENTE_COD.split(',');
                    }
                 
                    this.setState({
                        userId: data.KUSER,
                        username: data.USERNAME,
                        nome: data.NOME,
                        cognome: data.COGNOME,
                        eMailAddress: data.EMAIL_ADDRESS,
                        flagAdmin: data.FLAG_ADMIN == 'Y' ? true : false,
                        admin: data.FLAG_ADMIN == 'Y' ? true : false,
                        flagAttivo: data.FLAG_ATTIVO == 'Y' ? true : false,
                        flagDoppioProfilo: data.FLAG_DOPPIO_PROFILO == 'Y' ? true : false,
                        dataUltimaLogin: data.DATA_ULTIMA_LOGIN,
                        dataUltimaModifica: data.DATA_ULTIMA_MODIFICA,
                        dataInserimento: data.DATA_INSERIMENTO,
                        dataCancellazione: data.DATA_CANCELLAZIONE,
                        listaAgenteCod: data.AGENTE_COD ? data.AGENTE_COD : '',
                        loading: false,
                    });
                }
            })
            .catch((error) => {
                console.log('error LoadUser', error)
            });

            this.getUserRoles();
    }


    handleChange(event) {
        this.setState({ [event.target.name] : event.target.value});
    }

    handleChangeCheck(event) {
        if(event.target.checked){
            this.setState({ [event.target.name] : true});
        }else{
            this.setState({ [event.target.name] : false});
        }
    }
    async handleSubmitRoles(){
        this.getUserRoles();
       
    }
    
    setComponentSaveFalse = () => {
            this.setState({ childComponentSave: false });
      }

    handleSubmit(event) {

        try{
            if (this.state.admin !== true) {
                this.setState({childComponentSave: true})
            } else {
                this.setState({childComponentSave: false})
            }
            let first = true;
            const roles = this.state.roles;
            let grantedRoles = []
            for (let i = 0; i < roles.length; i++) {
                if(roles[i]['FLAG_GRANTED']=='Y'){
                    grantedRoles.push(roles[i]['ROLE_COD']);
                }
            }
            const roleCodesList = grantedRoles.join();

            let AgentCods = '';

            if(this.state.listaAgenteCod){
                AgentCods = this.state.listaAgenteCod.split(',');
                
                for (let index = 0; index < AgentCods.length; index++) {
                    
                    let element = AgentCods[index].trim();

                    if(!element || element.length > 5 || element.includes(' ')){
                        throw new Error('Agent Cod. is not in compliance')
                    }

                    AgentCods[index] = element;
                }
                AgentCods = AgentCods.join(', ');
            }

            const params = {
                userId: this.state.userId,
                username: this.state.username,
                nome: this.state.nome,
                password: this.state.password,
                confirmPassword: this.state.confirmPassword,
                cognome: this.state.cognome,
                eMailAddress: this.state.eMailAddress,
                pRoleCodesList: roleCodesList,
                flagAdmin: this.state.flagAdmin == true ? 'Y' : 'N',
                flagAttivo: this.state.flagAttivo == true ? 'Y' : 'N',
                flagDoppioProfilo: this.state.flagDoppioProfilo == true ? 'Y' : 'N',
                pAgenteCod: AgentCods ? AgentCods : '',
            };

            if (this.state.userId == "new") {
                this.submitIns(event, params);
            } else {
                this.submitMod(event, params);
            }
            
            this.state.toggleEnabled === true ? this.state.flagAttivo === true ?  this.enableUser() :  this.disableUser() : null

            this.createUserRoleList();
            NotificationWrapper.success(<IntlMessages id="message.global.success"/>);

            this.setState({ toggleEnabled: false });
        }catch (e){
            if(e.message === "Agent Cod. is not in compliance"){
                NotificationWrapper.error(e.message);
            }else{
                NotificationWrapper.error(<IntlMessages id="users.saveUser.error"/>);
            }
        }
    }



    changeGranted(roleIndex){
        let roles = this.state.roles;
        const grantedValue = roles[roleIndex]['FLAG_GRANTED'];

        if(grantedValue == "Y"){
            roles[roleIndex]['FLAG_GRANTED'] = 'N';
        }else{
            roles[roleIndex]['FLAG_GRANTED'] = 'Y';
        }

        this.setState({ roles: roles});
    }

    createUserRoleList(){
        const roles = this.state.roles;
        let htmlList = [];

        for (let i = 0; i < roles.length; i++) {
            const roleDesc = roles[i]["ROLE_DESC"];
            const isGranted = roles[i]["FLAG_GRANTED"] === 'Y' ? true : false;

            if(isGranted) {
                htmlList.push(
                    <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        checked={this.state.admin ? false : true}
                        onChange={() => this.changeGranted(i)}
                        disabled={this.state.admin}
                      />
                    }
                    label={roleDesc}
                  />
                );
            }else{
                htmlList.push(
                    <FormControlLabel
                    control={
                      <Checkbox
                        color="default"
                        checked={false}
                        onChange={() => this.changeGranted(i)}
                        disabled={this.state.admin}
                        icon={<i className="zmdi zmdi-square-o zmdi-hc-fw"></i>}
                      />
                    }
                    label={roleDesc}
                  />
                );
            }
        }
        
        return htmlList;
    }

    handleChangeCheck(event) {
        if(event.target.checked){
            this.setState({ [event.target.name] : true});
        }else{
            this.setState({ [event.target.name] : false});
        }
    }

    
    async submitIns(event, params) {
        Nprogress.start();

        let response = null;

        try {
            response = await FetchService.post("/users/user-ins", params);
        } catch (error) {
            return;
        }

        const responseRetVal = await response.json();

        if(responseRetVal <= 0){
            let errorMessage = "";
            switch (responseRetVal) {
                case 0:
                case -1:
                    errorMessage = <IntlMessages id="error.global.generic"/>;
                    break;
                case -2:
                    errorMessage = <IntlMessages id="error.global.no-privileges"/>;
                    break;
                case -3:
                    errorMessage = <IntlMessages id="error-user-ins-3"/>;
                    break;
                case -4:
                    errorMessage = <IntlMessages id="error-user-ins-4"/>;
                    break;
                case -5:
                    errorMessage = <IntlMessages id="error-user-ins-5"/>;
                    break;
                case -6:
                    errorMessage = <IntlMessages id="error-user-ins-6"/>;
                    break;
                case -7:
                    errorMessage = <IntlMessages id="error-user-ins-7"/>;
                    break;
                default:
                    errorMessage = <IntlMessages id="error.global.generic"/>;
                    break;
            }

            NotificationWrapper.error(errorMessage);
            return;
        }

        //redirect to User Account Edit
        this.setState({
            linkEditId: responseRetVal,
            linkEdit: true,
        });

        

    }

    userRoleListChangeHandler(){
        this.setState({ refreshPrivilegesList : !this.state.refreshPrivilegesList});
    }

    async submitMod(event, params) {
        Nprogress.start();

        let response = null;
        try {
            response = await FetchService.post("/users/user-mod", params);
        } catch (error) {
            Nprogress.done();
            console.log(error)
            return;
        }

        const responseRetVal = await response.json();

        if(responseRetVal <= 0){
            let errorMessage = "";
            switch (responseRetVal) {
                case 0:
                case -1:
                    errorMessage = <IntlMessages id="error.global.generic"/>;
                    break;
                case -2:
                    errorMessage = <IntlMessages id="error.global.no-privileges"/>;
                    break;
                case -3:
                    errorMessage = <IntlMessages id="error-user-mod-3"/>;
                    break;
                case -4:
                    errorMessage = <IntlMessages id="error-user-mod-4"/>;
                    break;
                case -5:
                    errorMessage = <IntlMessages id="error-user-mod-5"/>;
                    break;
                case -6:
                    errorMessage = <IntlMessages id="error-user-mod-6"/>;
                    break;
                case -7:
                    errorMessage = <IntlMessages id="error-user-mod-7"/>;
                    break;
                default:
                    errorMessage = <IntlMessages id="error.global.generic"/>;
                    break;
            }

            NotificationWrapper.error(errorMessage);
            Nprogress.done();
            return;
        }

        this.setState({ admin : this.state.flagAdmin   });
        if (this.state.admin !== true) {
            this.setState({childComponentSave: true})
        } else {
            this.setState({childComponentSave: false})
        }
        //force child refresh
        this.setState({ refreshPrivilegesList : !this.state.refreshPrivilegesList});
        Nprogress.done();

        this.handleSubmitRoles();
        this.handleSubmitRoles();
        
    }

    formatDate(dbDate) {
        if (dbDate == null) {
            return '';
        }

        dbDate = dbDate.trim();

        if(dbDate == '' || (dbDate.length != 8 && dbDate.length != 14)){
            return '';
        }

        var Y = dbDate.substring(0,4);
        var M = dbDate.substring(4,6);
        var D = dbDate.substring(6,8);

        return D + "/" + M + "/" + Y;
    }

    formatTime(dbDate) {
        if(dbDate==null){
            return '';
        }
        var h = dbDate.substring(8,10);
        var m = dbDate.substring(10,12);
        var s = dbDate.substring(12,14);

        return h + ":" + m + ":" + s;
    }


    formatDateTime(dbDate) {
        if (dbDate === null)
            return "";

        return this.formatDate(dbDate) + " " + this.formatTime(dbDate);
    }

    async handleSubmitDel(event) {
        event.preventDefault();

        Nprogress.start();

        const params = {
            userId: this.state.userId,
        };

        let response = null;

        try {
            response = await FetchService.post("/users/user-del", params);
        } catch (error) {
            Nprogress.done();
            console.log(error, 'DELETE_USER')
            return;
        }

        const responseRetVal = await response.json();

        if(responseRetVal <= 0){
            let errorMessage = "";
            switch (responseRetVal) {
                case 0:
                case -1:
                    errorMessage = <IntlMessages id="error.global.generic"/>;
                    break;
                case -2:
                    errorMessage = <IntlMessages id="error.global.no-privileges"/>;
                    break;
                case -3:
                    errorMessage = <IntlMessages id="error-user-del-3"/>;
                    break;
                case -4:
                    errorMessage = <IntlMessages id="error-user-del-4"/>;
                    break;
            }

            NotificationWrapper.error(errorMessage);
            return;
        }
       
        NotificationWrapper.success('User deleted')
        Nprogress.done();
   
        this.setState({
            linkList: true,
          
        });
        

    }



       enableUser = async () => {
        const params = {
             userId: this.props.match.params.kuser
        }
        let response = null;
        try {
            response = await FetchService.post("/users/userEnable", params);
        } catch (error) {
            console.log(error)
        }
        const responseRetVal = await response.json();
        
        if(responseRetVal <= 0) {
          let errorMessage = "";
          switch (responseRetVal) {
              case 0:
                  errorMessage = <IntlMessages id="error.global.generic"/>;
                  break;
              default:
                  errorMessage = <IntlMessages id="error.global.generic"/>;
                  break;
          }
      
          NotificationWrapper.error(errorMessage);
          return;
        }
      
       }
       disableUser = async () => {
        const params = {
            userId: this.props.match.params.kuser
        }
        let response = null;
        try {
            response = await FetchService.post("/users/user-disable", params);
        } catch (error) {
            console.log(error)
        }
        const responseRetVal = await response.json();
    
        if(responseRetVal <= 0) {
          let errorMessage = "";
          switch (responseRetVal) {
              case 0:
                  errorMessage = <IntlMessages id="error.global.generic"/>;
                  break;
              default:
                  errorMessage = <IntlMessages id="error.global.generic"/>;
                  break;
          }
      
          NotificationWrapper.error(errorMessage);
          return;
        }
      
        }
    handleToggleForcePassword() {
        this.setState({
            modalForcePassword: !this.state.modalForcePassword,
            forcePassword: "",
            confirmForcePassword: "",
        });
    }
    //check Password
    isErrorForcePassword(){
        let retVal = false
        let forcePassword = this.state.forcePassword.trim();

        if(forcePassword != ""){
            if(forcePassword.length < 8 || forcePassword.length > 50){
                retVal = true;
            }
        }
        return retVal;
    }

    isErrorConfirmForcePassword(){
        let retVal = false
        let forcePassword = this.state.forcePassword.trim();
        let confirmForcePassword = this.state.confirmForcePassword.trim();

        if(confirmForcePassword!= ""){
            if(confirmForcePassword != forcePassword){
                retVal = true;
            }
        }
        return retVal;
    }

    isErrorPassword(){
        let retVal = false
        let password = this.state.password.trim();

        if(password != ""){
            if(password.length < 8 || password.length > 50){
                retVal = true;
            }
        }
        return retVal;
    }

    isErrorConfirmPassword(){
        let retVal = false
        let password = this.state.password.trim();
        let confirmPassword = this.state.confirmPassword.trim();

        if(confirmPassword!= ""){
            if(confirmPassword != password){
                retVal = true;
            }
        }
        return retVal;
    }

    isDisabledButtonForcePassword(){
        let forcePassword = this.state.forcePassword.trim();
        let confirmForcePassword = this.state.confirmForcePassword.trim();

        if(forcePassword.length >= 8 && forcePassword.length <= 50 && confirmForcePassword == forcePassword) {
            return false;
        }else{
            return true;
        }
    }
    async handleForcePassword(){

        const params = {
            kUser: this.state.userId,
            newPassword: this.state.forcePassword,
        };

        let response = null;
        try {
            response = await FetchService.post("/users/user-force-password", params);
        } catch (error) {
            Nprogress.done();
            // this.props.userSignOut();
            console.log(error)
            return;
        }

        const responseRetVal = await response.json();

        if(responseRetVal <= 0){
            let errorMessage = "";
            switch (responseRetVal) {
                case 0:
                case -1:
                    errorMessage = <IntlMessages id="error.global.generic"/>;
                    break;
                case -3:
                    errorMessage = <IntlMessages id="error-user-force-password-3"/>;
                    break;
                case -4:
                    errorMessage = <IntlMessages id="error-user-force-password-4"/>;
                    break;
                case -24:
                    errorMessage = <IntlMessages id="error.global.no-privileges"/>;
                    break;
                default:
                    errorMessage = <IntlMessages id="error.global.generic"/>;
                    break;
            }

            NotificationWrapper.error(errorMessage);
            Nprogress.done();
            return;
        }

        this.handleToggleForcePassword();
        NotificationWrapper.success(<IntlMessages id="message.global.success"/>);
        Nprogress.done();
    }

    
    getClassPassword(){
        let retVal = "form-control";
        let password = this.state.password.trim();
        let confirmPassword = this.state.confirmPassword.trim();

        if(this.state.userId == "new") {
            if (password != "") {
                if (password.length < 8 || password.length > 50) {
                    retVal = "form-control is-invalid";
                }
            }
        }
        return retVal;
    }

    getClassConfirmPassword(){
        let retVal = "form-control";
        let password = this.state.password.trim();
        let confirmPassword = this.state.confirmPassword.trim();

        if(this.state.userId == "new") {
            if (confirmPassword != "") {
                if (confirmPassword != password) {
                    retVal = "form-control is-invalid";
                }
            }
        }
        return retVal;
    }

    handleToggleDelUser() {
        this.setState({
            modalDelUser: !this.state.modalDelUser
        });
    }

    handleChangeFlagAdmin(event){
        this.setState(() => ({ flagAdmin : !this.state.flagAdmin,
                       }));
    }

    handleChangeFlagAttivo(event){
        this.setState({ flagAttivo : !this.state.flagAttivo });
        this.setState({ toggleEnabled: true });
    }

    handleChangeFlagDoppioProfilo(event){
        this.setState({ flagDoppioProfilo : !this.state.flagDoppioProfilo });
    }


    isDisabledButtonSubmit(){
        let retVal = false;

        if(this.state.userId == "new") {
            let username = this.state.username.trim();
            let eMailAddress = this.state.eMailAddress.trim();
            let nome = this.state.nome.trim();
            let cognome = this.state.cognome.trim();

            if( username != ""
             && eMailAddress != ""
             && nome != ""
             && cognome != ""
            ) {
                retVal = false;
            }else{
                retVal = true;
            }
        }

        return retVal;
    }

    handleClickShowPassword(event){
        event.preventDefault();
        this.setState({ showPassword : !this.state.showPassword});
    }

    handleClickShowConfirmPassword(event){
        event.preventDefault();
        this.setState({ showConfirmPassword : !this.state.showConfirmPassword});
    }

    handleClickShowForcePassword(event){
        event.preventDefault();
        this.setState({ showForcePassword : !this.state.showForcePassword});
    }

    handleClickShowConfirmForcePassword(event){
        event.preventDefault();
        this.setState({ showConfirmForcePassword : !this.state.showConfirmForcePassword});
    }

    getUserStatus(){
        let html = [];
        if(this.state.dataCancellazione == null) {
            return ('');
        }else {
            return (<div className="user-disabled"><strong>Disabled on: </strong>{this.formatDateTime(this.state.dataCancellazione)}</div>);
        }

    }

    handleChangeProducer(selectedOptions) {
        this.setState({listaAgenteCod: selectedOptions});
    }

    createLocationsList(){
        return this.state.locations.map(location => 
            {
                return <MenuItem value={location.KLOCATION}>{location.LOCATION_DESC}</MenuItem>
            })
    }

    render() {
        const {
            username,
            eMailAddress,
            nome,
            cognome,
            flagAdmin,
            flagAttivo,
            flagDoppioProfilo,
            listaAgenteCod,
            forcePassword,
            confirmForcePassword,
            showForcePassword,
            showConfirmForcePassword
        } = this.state;

        //link
        // if (this.state.linkEdit === true) {
        //     const linkEditAccount = `/app/settings/users/account-details/${this.state.linkEditId}`;
        //     return <Redirect to={linkEditAccount} push={true} />
        // }

        if (this.state.linkList === true) {
            return <Redirect to="/app/usersList" push={true} />
        }



        if (this.state.loading) {
            return (
                <div id="account-detail">
                </div>
            );
        }else {

            return (
                <div class="account-detail">
                    <MuiThemeProvider theme={theme}>

                        <div className="row d-flex container-cards">
                            <div className="col-lg-6 col-md-12 col-12 leftCard">

                                <CardBox styleName="col-lg-12 col-md-12 " >
                                    <form className="p-3">
                                        {/* <Link to="/app/usersList">
                                        <ArrowBackIcon className="ArrowBackIcon"/>
                                        </Link> */}

                                        <div className="HeadTitle">
                                        {this.state.userId!="new" 
                                        ? <h1 className=""><IntlMessages id="settings.user.account-details.title"/></h1>
                                         :<h1 className=""><IntlMessages id="settings.user.account-details.new.title"/></h1>
                                        }
                                        </div>
                                      
                                        <input type="hidden" name="userId" value={this.state.userId}/>

                                        <div className="row">
                                            <div className="col-lg-6 col-md-6 col-12 col-flex">
                                                    <TextField
                                                        label="Username"
                                                        name="username"
                                                        value={username}
                                                        onChange={this.handleChange.bind(this)}
                                                        style={{flexGrow: 1}}
                                                    />
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-12 col-flex">
                                                <TextField
                                                    label="Surname"
                                                    name="cognome"
                                                    value={cognome}
                                                    onChange={this.handleChange.bind(this)}
                                                    style={{flexGrow: 1}}
                                                />
                                            </div>
                                            
                                        </div>


                                        <div className="row">
                                            <div className="col-lg-6 col-md-6 col-12 col-flex">
                                                <TextField
                                                    label="Name"
                                                    name="nome"
                                                    value={nome}
                                                    onChange={this.handleChange.bind(this)}
                                                    style={{flexGrow: 1}}
                                                />
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-12 col-flex">
                                                <TextField
                                                    label="E-mail address"
                                                    name="eMailAddress"
                                                    value={eMailAddress}
                                                    onChange={this.handleChange.bind(this)}
                                                    style={{flexGrow: 1}}
                                                />
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-12 col-flex">
                                                <TextField
                                                    label="Agent Cod."
                                                    name="listaAgenteCod"
                                                    value={listaAgenteCod}
                                                    onChange={this.handleChange.bind(this)}
                                                    style={{flexGrow: 1}}
                                                />
                                            </div>
                                        </div>

                                        <div className="d-flex justify-content-between">
                                            <div className="badges">
                                                <div className="" style={{ marginTop: 10 }}>
                                                    <div>
                                                        <FormControlLabel
                                                            control={
                                                              <Switch
                                                                  name="flagDoppioProfilo"
                                                                  checked={flagDoppioProfilo}
                                                                  onChange={() => { this.handleChangeFlagDoppioProfilo(event) }}
                                                                  color="primary"
                                                                  disabled={listaAgenteCod == null || listaAgenteCod.length == 0}
                                                              />
                                                            }
                                                            label="Dual Profile"
                                                        />
                                                    </div>

                                                </div>
                                                <div>
                                                    <div>
                                                        <FormControlLabel
                                                           control={
                                                               <Switch
                                                                   name="flagAdmin"
                                                                   checked={flagAdmin}
                                                                   onChange={() => { this.handleChangeFlagAdmin(event) }}
                                                                   color="primary"
                                                               />
                                                           }
                                                            label="Administrator"
                                                        />
                                                    </div>
                                                </div>
                                                {this.state.userId != "new" && (
                                                    <div>
                                                        <div>
                                                            <FormControlLabel
                                                                control={
                                                                    <Switch
                                                                        name="flagAttivo"
                                                                        checked={flagAttivo}
                                                                        onChange={() => { this.handleChangeFlagAttivo(event) }}
                                                                        color="primary"
                                                                    />
                                                                }
                                                                label="Enabled"
                                                            />
                                                        </div>
                                                    </div>
                                                )}


                                            </div>
                                            {this.state.userId != "new" && (
                                            <div className="col-lg-6 col-md-12 col-12 HISTORY">
                                                <div className="col-lg-12 col-md-12 HISTORY ">
                                                    <div className="row">
                                                    <div className="col-12">
                                                        <h4>User history</h4>
                                                        {this.getUserStatus()}
                                                        <table>
                                                            <tbody>
                                                                <tr>
                                                                    <td><strong>Created on:</strong></td>
                                                                    <td>{this.formatDateTime(this.state.dataInserimento)}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td><strong>Last login on:</strong></td>
                                                                    <td>{this.formatDateTime(this.state.dataUltimaLogin)}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td><strong>Updated on:</strong></td>
                                                                    <td>{this.formatDateTime(this.state.dataUltimaModifica)}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    </div>
                                                </div>
                                            </div>
                                         )}

                                        </div>
                                            <div id="user-role-list" >
                                                <div className="row mt-4">
                                                    <div className="col-12">
                                                        <h3 className="mb-1">Roles:</h3>
                                                        {this.createUserRoleList()}
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                         
                                        <div className="submit-buttons ">
                           

                                            <div className="">
                                                <Modal isOpen={this.state.modalForcePassword} toggle={this.handleToggleForcePassword.bind(this)} className="modal-delete-confirm modal-password-force">
                                                    <ModalHeader toggle={this.handleToggleForcePassword.bind(this)}>Force Password</ModalHeader>
                                                    <ModalBody>
                                                        <div className="row legend">
                                                            <div className="col-12">
                                                                <span>(Password length must range from 8 to 50 characters)</span>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-lg-6 col-md-6 col-12 col-flex">
                                                                <FormControl style={{ flexGrow: 1 }}>
                                                                    <InputLabel htmlFor="password">New Password</InputLabel>
                                                                    <Input
                                                                        autoComplete="new-password"
                                                                        type={showForcePassword ? "text" : "password"}
                                                                        name="forcePassword"
                                                                        value={forcePassword}
                                                                        onChange={this.handleChange.bind(this)}
                                                                        error={this.isErrorForcePassword()}
                                                                        endAdornment={
                                                                            <InputAdornment position="end">
                                                                                <IconButton
                                                                                    onClick={() => this.handleClickShowForcePassword(event)}
                                                                                >
                                                                                    {showForcePassword ? <Visibility /> : <VisibilityOff />}
                                                                                </IconButton>
                                                                            </InputAdornment>
                                                                        }
                                                                    />
                                                                </FormControl>
                                                            </div>

                                                            <div className="col-lg-6 col-md-6 col-12 col-flex">
                                                                <FormControl style={{ flexGrow: 2 }}>
                                                                    <InputLabel htmlFor="password">Confirm New Password</InputLabel>
                                                                    <Input
                                                                        type={showConfirmForcePassword ? "text" : "password"}
                                                                        name="confirmForcePassword"
                                                                        value={confirmForcePassword}
                                                                        onChange={this.handleChange.bind(this)}
                                                                        error={this.isErrorConfirmForcePassword()}
                                                                        endAdornment={
                                                                            <InputAdornment position="end">
                                                                                <IconButton
                                                                                    onClick={() => this.handleClickShowConfirmForcePassword(event)}
                                                                                >
                                                                                    {showConfirmForcePassword ? <Visibility /> : <VisibilityOff />}
                                                                                </IconButton>
                                                                            </InputAdornment>
                                                                        }
                                                                    />
                                                                </FormControl>
                                                            </div>
                                                        </div>
                                                    </ModalBody>
                                                    <ModalFooter>
                                                        <Button variant="contained" color="primary" onClick={this.handleForcePassword.bind(this)} disabled={this.isDisabledButtonForcePassword()}>Force Password</Button>
                                                        <Button variant="contained" onClick={this.handleToggleForcePassword.bind(this)}>Cancel</Button>
                                                    </ModalFooter>
                                                </Modal>
                                            </div>
                                         

                                                
                                            <Modal isOpen={this.state.modalDelUser} toggle={this.handleToggleDelUser.bind(this)} className="modal-delete-confirm">
                                                <ModalHeader toggle={this.handleToggleDelUser.bind(this)}>Delete User</ModalHeader>
                                                <ModalBody>
                                                    <IntlMessages id="settings.user.delete.confirm" /><br />
                                                </ModalBody>
                                                <ModalFooter>
                                                    <Button variant="contained" color="secondary" onClick={this.handleSubmitDel.bind(this)}>Delete</Button>{' '}
                                                    <Button variant="contained" onClick={this.handleToggleDelUser.bind(this)}>Cancel</Button>
                                                </ModalFooter>
                                            </Modal>

                                        </div>
                                    </form>
                                </CardBox>
                                
                            </div>

                            <div className="buttons-total-save">
                                <span className="button-opacity">
                                    <Button variant="contained" color="primary" onClick={() => this.handleSubmit(event)} disabled={this.isDisabledButtonSubmit()}>Save user</Button>

                                    <span className="button btn-force-psw">
                                        <Button variant="contained" color="primary" onClick={() => this.handleToggleForcePassword(event)}>Force Password</Button>
                                    </span>

                                    <span className="button DeleteUser">
                                        <Button variant="contained" color="secondary" onClick={() => { this.handleToggleDelUser(event) }}>Delete User</Button>
                                    </span>
                                </span>
                            </div>

                            <div className="col-lg-6 col-md-12 col-12 col-sm-12">
                                <CardBox styleName="col-lg-12 col-md-12 cardbox">
                                    <div className="HeadTitle">
                                        {this.state.userId != "new"
                                            ? <h1 className=""><IntlMessages id="settings.user.account-details.title" /></h1>
                                            : <h1 className=""><IntlMessages id="settings.user.account-details.new.title" /></h1>
                                        }
                                    </div>
                                    <PrivilegeList kUser={this.props.match.params.kuser} admin={this.state.admin} refresh={this.state.refreshPrivilegesList}  save={this.state.childComponentSave} setSaveFalse={this.setComponentSaveFalse}/>
                                </CardBox>
                            
                            </div>

                        

                        </div>

                    </MuiThemeProvider>
                    
                </div>
            );
        }
    }
}

export default connect(null)(UserDetails);
