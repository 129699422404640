import React from "react";
import "./privilegeList.css";
import FetchService from 'services/FetchService';
import {Button} from "reactstrap";
import Nprogress from "nprogress";
import NotificationWrapper from "util/NotificationWrapper";
import IntlMessages from 'util/IntlMessages';
import {connect} from "react-redux";

import MaterialButton from '@material-ui/core/Button';

class privilegeList extends React.Component {

    icoPriv = [];
    icoDeny = [];

    constructor(props) {
        super(props);

        this.state = {
            kUser: this.props.kUser,
            save: this.props.save,
            admin: this.props.admin,
            modified: false,
            privileges: [],
            loading: true,
            refresh: false,
        
        };

        const adminClass = this.state.admin ? 'UserIsAnAdmin' : '';

        this.icoPriv['W'] = <div className={`write alert-success ${adminClass}`} title="Write"><i className="zmdi zmdi-edit zmdi-hc-fw"></i></div>;
        this.icoPriv['R'] = <div className={`read alert-warning ${adminClass}`} title="Read"><i className="zmdi zmdi-eye zmdi-hc-fw"></i></div>;
        this.icoPriv['N'] = <div className={`denied alert-secondary ${adminClass}`} title="Denied"><i className="zmdi zmdi-block zmdi-hc-fw"></i></div>;
        
        this.icoDeny['Y'] = <div className={`denied alert-secondary ${adminClass}`} title="Denied"><i className="zmdi zmdi-block zmdi-hc-fw"></i></div>;
        this.icoDeny['N'] = <div className={`no-denied success-icon-denied ${adminClass}`} title="Allowed"><i className="zmdi zmdi-block zmdi-hc-fw"></i></div>;
        
    }

    componentDidMount() {
        this.getPrivileges();
    }

    componentDidUpdate() {
        if (this.props.save === true && this.state.modified === true) {
            this.handleSubmit();
            this.setState({modified:false})
        } 
        
    }

    componentWillReceiveProps(props) {
        const { refresh } = this.props;
        if (props.refresh !== refresh) {
            this.getPrivileges();
        }

        this.setState({admin : props.admin});
    }

    getPrivileges() {
        const params = {kUser: this.state.kUser};

        FetchService.get("/users/user-get-privileges", params)
            .then((data) => {
                this.setState({ privileges: data, loading : false});
              
            })
            .catch((error) => {
                console.log(error)
            });
    }

    changeIcoPriv(colPriv, colIndex){
        if (this.state.admin === false) {
            this.setState({modified: true})
            let privileges = this.state.privileges;

            const colValue = privileges[colIndex][colPriv];
    
            if(colIndex == 0){ //direct privs columns
                if(colValue == "W"){
                    privileges[colIndex][colPriv] = 'N';
                }else if(colValue == "R"){
                    privileges[colIndex][colPriv] = 'W';
                }else if(colValue == "N"){
                    privileges[colIndex][colPriv] = 'R';
                }
            }else if(colIndex == 2){ 
                if(colValue == "Y"){
                    privileges[colIndex][colPriv] = 'N';
                }else if(colValue == "N"){
                    privileges[colIndex][colPriv] = 'Y';
                }
            }
            
            this.setState({ privileges: privileges});
        } else {
            NotificationWrapper.error('You cannot modify admin privileges.');

        }
      
    }
    
    async handleSubmit(event) {

     
        const privileges = this.state.privileges;

        const paramsPrivs = {
            userId: this.state.kUser,
            pPrivLoadNielsenInvGRP:        privileges[0].P_LOAD_NIELSEN_INV_GRP,
            pPrivLoadNielsenInvShr:        privileges[0].P_LOAD_NIELSEN_INV_SHR,
            pPrivLoadAriannaGRP:           privileges[0].P_LOAD_ARIANNA_GRP    ,
            pPrivLoadDiscoveryAnag:        privileges[0].P_LOAD_DISCOVERY_ANAG ,
            pPrivLoadDiscoveryInv:         privileges[0].P_LOAD_DISCOVERY_INV  ,
            pPrivMapClientiNielsen:        privileges[0].P_MAP_CLIENTI_NIELSEN ,
            pPrivMapClientiDiscovery:      privileges[0].P_MAP_CLIENTI_DISCOVERY,
            pPrivMapClientiArianna:        privileges[0].P_MAP_CLIENTI_ARIANNA ,
            pPrivMapTargetNielsen:         privileges[0].P_MAP_TARGET_NIELSEN  ,
            pPrivMapTargetArianna:         privileges[0].P_MAP_TARGET_ARIANNA  ,
            pPrivMapCanaliArianna:         privileges[0].P_MAP_CANALI_ARIANNA  ,
            pPrivMapCanaliDiscovery:       privileges[0].P_MAP_CANALI_DISCOVERY,
            pPrivClienti:                  privileges[0].P_CLIENTI             ,
            pPrivAgenzie:                  privileges[0].P_AGENZIE             ,
            pPrivProdottiNielsen:          privileges[0].P_PRODOTTI_NIELSEN    ,
            pPrivProdottiArianna:          privileges[0].P_PRODOTTI_ARIANNA    ,
            pPrivProdottiDiscovery:        privileges[0].P_PRODOTTI_DISCOVERY  ,
            pPrivCentriMedia:              privileges[0].P_CENTRI_MEDIA        ,
            pPrivCanali:                   privileges[0].P_CANALI              ,
            pPrivTarget:                   privileges[0].P_TARGET              ,
            pPrivRptDiscoveryAnnoMese:     privileges[0].P_RPT_DISCOVERY_ANNO_MESE,
            pPrivRptCompetitorsAnno:       privileges[0].P_RPT_COMPETITORS_ANNO,
            pPrivBusinessTypes:            privileges[0].P_BUSINESS_TYPES      ,
            pPrivReportLastDates:          privileges[0].P_REPORT_LAST_DATES   ,
            pPrivFattoriConversione:       privileges[0].P_FATTORI_CONVERSIONE ,
            pPrivClientiAgenzie:           privileges[0].P_CLIENTI_AGENZIE     ,
            pPrivAlerts:                   privileges[0].P_ALERTS              ,
            pPrivImpersonate:              privileges[0].P_IMPERSONATE         ,
            pPrivRestrictedAccess:         privileges[0].P_RESTRICTED_ACCESS   ,
            pPrivPolComm:                  privileges[0].P_POL_COMM            ,
            pPrivPolCommImport:            privileges[0].P_POL_COMM_IMPORT     ,
            pPrivPortafoglio:              privileges[0].P_PORTAFOGLIO         ,
            pPrivRoobeek:                  privileges[0].P_ROOBEEK             ,
            pPrivCbContracts:              privileges[0].P_CB_CONTRACTS        ,
            pPrivPipelineForecast:         privileges[0].P_PIPELINE_FORECAST   ,
            pPrivPipelineSnapshot:         privileges[0].P_PIPELINE_SNAPSHOT   ,
            pPrivTrackerAvailab  :         privileges[0].P_TRACKER_AVAILAB     ,
            pPrivTrackerAvailabPillar:     privileges[0].P_TRACKER_PILLAR      ,
            pPrivTrackerPillarDaily:       privileges[0].P_TRACKER_PILLAR_DAILY,
            pPrivOverbooking     :         privileges[0].P_OVERBOOKING         ,
            pPrivOverbookingBacino:        privileges[0].P_OVERBOOKING_BACINO  ,    
            pPrivProvvCommissions:         privileges[0].P_PROVV_COMMISSIONS   ,  
            pPrivProvvStatements:          privileges[0].P_PROVV_STATEMENTS    ,  
            pPrivAgenda:                   privileges[0].P_AGENDA              ,
            pPrivAnalysisTarget:           privileges[0].P_ANALYSIS_TARGET      
        };
        const paramsDeny = {
            userId: this.state.kUser,
            pPrivLoadNielsenInvGRP:        privileges[2].P_LOAD_NIELSEN_INV_GRP,
            pPrivLoadNielsenInvShr:        privileges[2].P_LOAD_NIELSEN_INV_SHR,
            pPrivLoadAriannaGRP:           privileges[2].P_LOAD_ARIANNA_GRP    ,
            pPrivLoadDiscoveryAnag:        privileges[2].P_LOAD_DISCOVERY_ANAG ,
            pPrivLoadDiscoveryInv:         privileges[2].P_LOAD_DISCOVERY_INV  ,
            pPrivMapClientiNielsen:        privileges[2].P_MAP_CLIENTI_NIELSEN ,
            pPrivMapClientiDiscovery:      privileges[2].P_MAP_CLIENTI_DISCOVERY,
            pPrivMapClientiArianna:        privileges[2].P_MAP_CLIENTI_ARIANNA ,
            pPrivMapTargetNielsen:         privileges[2].P_MAP_TARGET_NIELSEN  ,
            pPrivMapTargetArianna:         privileges[2].P_MAP_TARGET_ARIANNA  ,
            pPrivMapCanaliArianna:         privileges[2].P_MAP_CANALI_ARIANNA  ,
            pPrivMapCanaliDiscovery:       privileges[2].P_MAP_CANALI_DISCOVERY,
            pPrivClienti:                  privileges[2].P_CLIENTI             ,
            pPrivAgenzie:                  privileges[2].P_AGENZIE             ,
            pPrivProdottiNielsen:          privileges[2].P_PRODOTTI_NIELSEN    ,
            pPrivProdottiArianna:          privileges[2].P_PRODOTTI_ARIANNA    ,
            pPrivProdottiDiscovery:        privileges[2].P_PRODOTTI_DISCOVERY  ,
            pPrivCentriMedia:              privileges[2].P_CENTRI_MEDIA        ,
            pPrivCanali:                   privileges[2].P_CANALI              ,
            pPrivTarget:                   privileges[2].P_TARGET              ,
            pPrivRptDiscoveryAnnoMese:     privileges[2].P_RPT_DISCOVERY_ANNO_MESE,
            pPrivRptCompetitorsAnno:       privileges[2].P_RPT_COMPETITORS_ANNO,
            pPrivBusinessTypes:            privileges[2].P_BUSINESS_TYPES      ,
            pPrivReportLastDates:          privileges[2].P_REPORT_LAST_DATES   ,
            pPrivFattoriConversione:       privileges[2].P_FATTORI_CONVERSIONE ,
            pPrivClientiAgenzie:           privileges[2].P_CLIENTI_AGENZIE     ,
            pPrivAlerts:                   privileges[2].P_ALERTS              ,
            pPrivImpersonate:              privileges[2].P_IMPERSONATE         ,
            pPrivRestrictedAccess:         privileges[2].P_RESTRICTED_ACCESS   ,
            pPrivPolComm:                  privileges[2].P_POL_COMM            ,
            pPrivPolCommImport:            privileges[2].P_POL_COMM_IMPORT     ,
            pPrivPortafoglio:              privileges[2].P_PORTAFOGLIO         ,
            pPrivRoobeek:                  privileges[2].P_ROOBEEK             ,
            pPrivCbContracts:              privileges[2].P_CB_CONTRACTS        ,
            pPrivPipelineForecast:         privileges[2].P_PIPELINE_FORECAST   ,
            pPrivPipelineSnapshot:         privileges[2].P_PIPELINE_SNAPSHOT   ,
            pPrivTrackerAvailab    :       privileges[2].P_TRACKER_AVAILAB     ,
            pPrivTrackerAvailabPillar:     privileges[2].P_TRACKER_PILLAR      ,
            pPrivTrackerPillarDaily:       privileges[2].P_TRACKER_PILLAR_DAILY,
            pPrivOverbooking     :         privileges[2].P_OVERBOOKING         ,
            pPrivOverbookingBacino:        privileges[2].P_OVERBOOKING_BACINO  ,   
            pPrivProvvCommissions:         privileges[2].P_PROVV_COMMISSIONS   ,
            pPrivProvvStatements:          privileges[2].P_PROVV_STATEMENTS    ,
            pPrivAgenda:                   privileges[2].P_AGENDA              ,
            pPrivAnalysisTarget:           privileges[2].P_ANALYSIS_TARGET
        };

        let responseStatus = 0;
        let responseRetVal = 0;

        //update privileges
        await FetchService.post('/users/user-mod-privs', paramsPrivs)
                .then((response) => {
                    responseStatus = response.status;
                    return response.json();
                }).then((data) => {
                    responseRetVal = data;
                })
                .catch((error) => {
                    console.log(error)
                });

        if (responseStatus != 200){
            NotificationWrapper.error(<IntlMessages id="error.global.generic"/>);
            return;
        }else if(responseRetVal <= 0){
            let errorMessage = "";
            switch (responseRetVal) {
                case 0:
                    errorMessage = <IntlMessages id="error.global.generic"/>;
                    break;
                case -1:
                    errorMessage = <IntlMessages id="error.global.generic"/>;
                    break;
                case -2:
                    errorMessage = <IntlMessages id="error.global.no-privileges"/>;
                    break;
                case -3:
                    errorMessage = <IntlMessages id="error-user-mod-priv-3"/>;
                    break;
                case -4:
                    errorMessage = <IntlMessages id="error-user-mod-priv-4"/>;
                    break;
                default:
                    errorMessage = <IntlMessages id="error.global.generic"/>;
                    break;
            }
            NotificationWrapper.error(errorMessage);
            return;
        }
        //update deny
        await FetchService.post("/users/user-mod-deny", paramsDeny)
            .then((response) => {
                responseStatus = response.status;
                return response.json();
            }).then((data) => {
                responseRetVal = data;
            }).catch((error) => {
                console.log(error)
            });

        if (responseStatus != 200){
            NotificationWrapper.error(<IntlMessages id="error.global.generic"/>);
            return;
        }else if(responseRetVal <= 0){
            let errorMessage = "";
            switch (responseRetVal) {
                case 0:
                case -1:
                    errorMessage = <IntlMessages id="error.global.generic"/>;
                    break;
                case -2:
                    errorMessage = <IntlMessages id="error.global.no-privileges"/>;
                    break;
                case -3:
                    errorMessage = <IntlMessages id="error-user-mod-priv-3"/>;
                    break;
                case -4:
                    errorMessage = <IntlMessages id="error-user-mod-priv-4"/>;
                    break;
                default:
                    errorMessage = <IntlMessages id="error.global.generic"/>;
                    break;
            }
            NotificationWrapper.error(errorMessage);
            return;
        }

        this.getPrivileges();
        this.props.setSaveFalse();
    }

    render() {
   
        const {privileges} = this.state;

        if (this.state.loading) {
            return (
                <div id="privileges-list">
                </div>
            );
        }else {
            return (
                <div id="privileges-list">
                    <div className="HeadTitle">
                        <h1 className="">Privilege List</h1>
                        <div className="row p-2">
                            <div className="col-12">
                                <div class="privileges-list PrivilegeSticky">
                                    <div class="blue-title">Result</div>
                                    <div class="blue-title">Deny</div>
                                    <div class="blue-title">Roles</div>
                                    <div class="blue-title">Explicit</div>
                                </div>

                            </div>
                        </div>
                      </div>
                           
                    <div className="row p-2">
                        <div className="col-12">
                            <div className="privileges-list title">Upload / Import</div>

                            <div className="privileges-list">
                                <div className="privileges-title">Nielsen (AQX): Standard Investments / GRP</div>
                                <div className="privileges-ico ico-no-link">{this.icoPriv[privileges[3].P_LOAD_NIELSEN_INV_GRP]}</div>
                                <div className="privileges-ico ico-link" onClick={() => this.changeIcoPriv("P_LOAD_NIELSEN_INV_GRP", 2)}>{this.icoDeny[privileges[2].P_LOAD_NIELSEN_INV_GRP]}</div>
                                <div className="privileges-ico ico-no-link">{this.icoPriv[privileges[1].P_LOAD_NIELSEN_INV_GRP]}</div>
                                <div className="privileges-ico ico-link" onClick={() => this.changeIcoPriv("P_LOAD_NIELSEN_INV_GRP", 0)}>{this.icoPriv[privileges[0].P_LOAD_NIELSEN_INV_GRP]}</div>
                            </div>

                            <div className="privileges-list">
                                <div className="privileges-title">Arianna: GRP </div>
                                <div className="privileges-ico ico-no-link">{this.icoPriv[privileges[3].P_LOAD_ARIANNA_GRP]}</div>
                                <div className="privileges-ico ico-link" onClick={() => this.changeIcoPriv("P_LOAD_ARIANNA_GRP", 2)}>{this.icoDeny[privileges[2].P_LOAD_ARIANNA_GRP]}</div>
                                <div className="privileges-ico ico-no-link">{this.icoPriv[privileges[1].P_LOAD_ARIANNA_GRP]}</div>
                                <div className="privileges-ico ico-link" onClick={() => this.changeIcoPriv("P_LOAD_ARIANNA_GRP", 0)}>{this.icoPriv[privileges[0].P_LOAD_ARIANNA_GRP]}</div>
                            </div>

                            <div className="privileges-list">
                                <div className="privileges-title">Discovery (BTS): Advertisers / Products / Agents / Agents Holdings</div>
                                <div className="privileges-ico ico-no-link">{this.icoPriv[privileges[3].P_LOAD_DISCOVERY_ANAG]}</div>
                                <div className="privileges-ico ico-link" onClick={() => this.changeIcoPriv("P_LOAD_DISCOVERY_ANAG", 2)}>{this.icoDeny[privileges[2].P_LOAD_DISCOVERY_ANAG]}</div>
                                <div className="privileges-ico ico-no-link">{this.icoPriv[privileges[1].P_LOAD_DISCOVERY_ANAG]}</div>
                                <div className="privileges-ico ico-link" onClick={() => this.changeIcoPriv("P_LOAD_DISCOVERY_ANAG", 0)}>{this.icoPriv[privileges[0].P_LOAD_DISCOVERY_ANAG]}</div>
                            </div>

                            <div className="privileges-list">
                                <div className="privileges-title">Discovery (BTS): Standard Investments / Share Investments</div>
                                <div className="privileges-ico ico-no-link">{this.icoPriv[privileges[3].P_LOAD_DISCOVERY_INV]}</div>
                                <div className="privileges-ico ico-link" onClick={() => this.changeIcoPriv("P_LOAD_DISCOVERY_INV", 2)}>{this.icoDeny[privileges[2].P_LOAD_DISCOVERY_INV]}</div>
                                <div className="privileges-ico ico-no-link">{this.icoPriv[privileges[1].P_LOAD_DISCOVERY_INV]}</div>
                                <div className="privileges-ico ico-link" onClick={() => this.changeIcoPriv("P_LOAD_DISCOVERY_INV", 0)}>{this.icoPriv[privileges[0].P_LOAD_DISCOVERY_INV]}</div>
                            </div>

                            <div className="privileges-list title">General Privileges</div>

                            <div className="privileges-list">
                                <div className="privileges-title">Analysis Target</div>
                                <div className="privileges-ico ico-no-link">{this.icoPriv[privileges[3].P_ANALYSIS_TARGET]}</div>
                                <div className="privileges-ico ico-link" onClick={() => this.changeIcoPriv("P_ANALYSIS_TARGET", 2)}>{this.icoDeny[privileges[2].P_ANALYSIS_TARGET]}</div>
                                <div className="privileges-ico ico-no-link">{this.icoPriv[privileges[1].P_ANALYSIS_TARGET]}</div>
                                <div className="privileges-ico ico-link" onClick={() => this.changeIcoPriv("P_ANALYSIS_TARGET", 0)}>{this.icoPriv[privileges[0].P_ANALYSIS_TARGET]}</div>
                            </div>
                            
                            <div className="privileges-list">
                                <div className="privileges-title">Agents Holdings management</div>
                                <div className="privileges-ico ico-no-link">{this.icoPriv[privileges[3].P_CENTRI_MEDIA]}</div>
                                <div className="privileges-ico ico-link" onClick={() => this.changeIcoPriv("P_CENTRI_MEDIA", 2)}>{this.icoDeny[privileges[2].P_CENTRI_MEDIA]}</div>
                                <div className="privileges-ico ico-no-link">{this.icoPriv[privileges[1].P_CENTRI_MEDIA]}</div>
                                <div className="privileges-ico ico-link" onClick={() => this.changeIcoPriv("P_CENTRI_MEDIA", 0)}>{this.icoPriv[privileges[0].P_CENTRI_MEDIA]}</div>
                            </div>

                            <div className="privileges-list">
                                <div className="privileges-title">Agents management</div>
                                <div className="privileges-ico ico-no-link">{this.icoPriv[privileges[3].P_AGENZIE]}</div>
                                <div className="privileges-ico ico-link" onClick={() => this.changeIcoPriv("P_AGENZIE", 2)}>{this.icoDeny[privileges[2].P_AGENZIE]}</div>
                                <div className="privileges-ico ico-no-link">{this.icoPriv[privileges[1].P_AGENZIE]}</div>
                                <div className="privileges-ico ico-link" onClick={() => this.changeIcoPriv("P_AGENZIE", 0)}>{this.icoPriv[privileges[0].P_AGENZIE]}</div>
                            </div>

                            <div className="privileges-list">
                                <div className="privileges-title">Forced agents management</div>
                                <div className="privileges-ico ico-no-link">{this.icoPriv[privileges[3].P_CLIENTI_AGENZIE]}</div>
                                <div className="privileges-ico ico-link" onClick={() => this.changeIcoPriv("P_CLIENTI_AGENZIE", 2)}>{this.icoDeny[privileges[2].P_CLIENTI_AGENZIE]}</div>
                                <div className="privileges-ico ico-no-link">{this.icoPriv[privileges[1].P_CLIENTI_AGENZIE]}</div>
                                <div className="privileges-ico ico-link" onClick={() => this.changeIcoPriv("P_CLIENTI_AGENZIE", 0)}>{this.icoPriv[privileges[0].P_CLIENTI_AGENZIE]}</div>
                            </div>             

                            <div className="privileges-list">
                                <div className="privileges-title">Channels management</div>
                                <div className="privileges-ico ico-no-link">{this.icoPriv[privileges[3].P_CANALI]}</div>
                                <div className="privileges-ico ico-link" onClick={() => this.changeIcoPriv("P_CANALI", 2)}>{this.icoDeny[privileges[2].P_CANALI]}</div>
                                <div className="privileges-ico ico-no-link">{this.icoPriv[privileges[1].P_CANALI]}</div>
                                <div className="privileges-ico ico-link" onClick={() => this.changeIcoPriv("P_CANALI", 0)}>{this.icoPriv[privileges[0].P_CANALI]}</div>
                            </div>                            

                            <div className="privileges-list">
                                <div className="privileges-title">Targets management</div>
                                <div className="privileges-ico ico-no-link">{this.icoPriv[privileges[3].P_TARGET]}</div>
                                <div className="privileges-ico ico-link" onClick={() => this.changeIcoPriv("P_TARGET", 2)}>{this.icoDeny[privileges[2].P_TARGET]}</div>
                                <div className="privileges-ico ico-no-link">{this.icoPriv[privileges[1].P_TARGET]}</div>
                                <div className="privileges-ico ico-link" onClick={() => this.changeIcoPriv("P_TARGET", 0)}>{this.icoPriv[privileges[0].P_TARGET]}</div>
                            </div>

                            <div className="privileges-list">
                                <div className="privileges-title">Business types management</div>
                                <div className="privileges-ico ico-no-link">{this.icoPriv[privileges[3].P_BUSINESS_TYPES]}</div>
                                <div className="privileges-ico ico-link" onClick={() => this.changeIcoPriv("P_BUSINESS_TYPES", 2)}>{this.icoDeny[privileges[2].P_BUSINESS_TYPES]}</div>
                                <div className="privileges-ico ico-no-link">{this.icoPriv[privileges[1].P_BUSINESS_TYPES]}</div>
                                <div className="privileges-ico ico-link" onClick={() => this.changeIcoPriv("P_BUSINESS_TYPES", 0)}>{this.icoPriv[privileges[0].P_BUSINESS_TYPES]}</div>
                            </div>

                            <div className="privileges-list">
                                <div className="privileges-title">Report last dates management</div>
                                <div className="privileges-ico ico-no-link">{this.icoPriv[privileges[3].P_REPORT_LAST_DATES]}</div>
                                <div className="privileges-ico ico-link" onClick={() => this.changeIcoPriv("P_REPORT_LAST_DATES", 2)}>{this.icoDeny[privileges[2].P_REPORT_LAST_DATES]}</div>
                                <div className="privileges-ico ico-no-link">{this.icoPriv[privileges[1].P_REPORT_LAST_DATES]}</div>
                                <div className="privileges-ico ico-link" onClick={() => this.changeIcoPriv("P_REPORT_LAST_DATES", 0)}>{this.icoPriv[privileges[0].P_REPORT_LAST_DATES]}</div>
                            </div>


                            <div className="privileges-list">
                                <div className="privileges-title">Conversion factors management</div>
                                <div className="privileges-ico ico-no-link">{this.icoPriv[privileges[3].P_FATTORI_CONVERSIONE]}</div>
                                <div className="privileges-ico ico-link" onClick={() => this.changeIcoPriv("P_FATTORI_CONVERSIONE", 2)}>{this.icoDeny[privileges[2].P_FATTORI_CONVERSIONE]}</div>
                                <div className="privileges-ico ico-no-link">{this.icoPriv[privileges[1].P_FATTORI_CONVERSIONE]}</div>
                                <div className="privileges-ico ico-link" onClick={() => this.changeIcoPriv("P_FATTORI_CONVERSIONE", 0)}>{this.icoPriv[privileges[0].P_FATTORI_CONVERSIONE]}</div>
                            </div>

                            <div className="privileges-list">
                                <div className="privileges-title">Alerts</div>
                                <div className="privileges-ico ico-no-link">{this.icoPriv[privileges[3].P_ALERTS]}</div>
                                <div className="privileges-ico ico-link" onClick={() => this.changeIcoPriv("P_ALERTS", 2)}>{this.icoDeny[privileges[2].P_ALERTS]}</div>
                                <div className="privileges-ico ico-no-link">{this.icoPriv[privileges[1].P_ALERTS]}</div>
                                <div className="privileges-ico ico-link" onClick={() => this.changeIcoPriv("P_ALERTS", 0)}>{this.icoPriv[privileges[0].P_ALERTS]}</div>
                            </div>

                           
                            <div className="privileges-list">
                                <div className="privileges-title">Impersonate</div>
                                <div className="privileges-ico ico-no-link">{this.icoPriv[privileges[3].P_IMPERSONATE]}</div>
                                <div className="privileges-ico ico-link" onClick={() => this.changeIcoPriv("P_IMPERSONATE", 2)}>{this.icoDeny[privileges[2].P_IMPERSONATE]}</div>
                                <div className="privileges-ico ico-no-link">{this.icoPriv[privileges[1].P_IMPERSONATE]}</div>
                                <div className="privileges-ico ico-link" onClick={() => this.changeIcoPriv("P_IMPERSONATE", 0)}>{this.icoPriv[privileges[0].P_IMPERSONATE]}</div>
                            </div>

                            <div className="privileges-list">
                                <div className="privileges-title">Restricted Access</div>
                                <div className="privileges-ico ico-no-link">{this.icoPriv[privileges[3].P_RESTRICTED_ACCESS]}</div>
                                <div className="privileges-ico ico-link" onClick={() => this.changeIcoPriv("P_RESTRICTED_ACCESS", 2)}>{this.icoDeny[privileges[2].P_RESTRICTED_ACCESS]}</div>
                                <div className="privileges-ico ico-no-link">{this.icoPriv[privileges[1].P_RESTRICTED_ACCESS]}</div>
                                <div className="privileges-ico ico-link" onClick={() => this.changeIcoPriv("P_RESTRICTED_ACCESS", 0)}>{this.icoPriv[privileges[0].P_RESTRICTED_ACCESS]}</div>
                            </div>


                            <div className="privileges-list">
                                <div className="privileges-title">Portfolio</div>
                                <div className="privileges-ico ico-no-link">{this.icoPriv[privileges[3].P_PORTAFOGLIO]}</div>
                                <div className="privileges-ico ico-link" onClick={() => this.changeIcoPriv("P_PORTAFOGLIO", 2)}>{this.icoDeny[privileges[2].P_PORTAFOGLIO]}</div>
                                <div className="privileges-ico ico-no-link">{this.icoPriv[privileges[1].P_PORTAFOGLIO]}</div>
                                <div className="privileges-ico ico-link" onClick={() => this.changeIcoPriv("P_PORTAFOGLIO", 0)}>{this.icoPriv[privileges[0].P_PORTAFOGLIO]}</div>
                            </div>

                            <div className="privileges-list title">Mapping</div>
                            <div className="privileges-list">
                                <div className="privileges-title">Nielsen Advertisers</div>
                                <div className="privileges-ico ico-no-link">{this.icoPriv[privileges[3].P_MAP_CLIENTI_NIELSEN]}</div>
                                <div className="privileges-ico ico-link" onClick={() => this.changeIcoPriv("P_MAP_CLIENTI_NIELSEN", 2)}>{this.icoDeny[privileges[2].P_MAP_CLIENTI_NIELSEN]}</div>
                                <div className="privileges-ico ico-no-link">{this.icoPriv[privileges[1].P_MAP_CLIENTI_NIELSEN]}</div>
                                <div className="privileges-ico ico-link" onClick={() => this.changeIcoPriv("P_MAP_CLIENTI_NIELSEN", 0)}>{this.icoPriv[privileges[0].P_MAP_CLIENTI_NIELSEN]}</div>
                            </div>
                            <div className="privileges-list">
                                <div className="privileges-title">Discovery Advertisers</div>
                                <div className="privileges-ico ico-no-link">{this.icoPriv[privileges[3].P_MAP_CLIENTI_DISCOVERY]}</div>
                                <div className="privileges-ico ico-link" onClick={() => this.changeIcoPriv("P_MAP_CLIENTI_DISCOVERY", 2)}>{this.icoDeny[privileges[2].P_MAP_CLIENTI_DISCOVERY]}</div>
                                <div className="privileges-ico ico-no-link">{this.icoPriv[privileges[1].P_MAP_CLIENTI_DISCOVERY]}</div>
                                <div className="privileges-ico ico-link" onClick={() => this.changeIcoPriv("P_MAP_CLIENTI_DISCOVERY", 0)}>{this.icoPriv[privileges[0].P_MAP_CLIENTI_DISCOVERY]}</div>
                            </div>
                            <div className="privileges-list">
                                <div className="privileges-title">Arianna Advertisers</div>
                                <div className="privileges-ico ico-no-link">{this.icoPriv[privileges[3].P_MAP_CLIENTI_ARIANNA]}</div>
                                <div className="privileges-ico ico-link" onClick={() => this.changeIcoPriv("P_MAP_CLIENTI_ARIANNA", 2)}>{this.icoDeny[privileges[2].P_MAP_CLIENTI_ARIANNA]}</div>
                                <div className="privileges-ico ico-no-link">{this.icoPriv[privileges[1].P_MAP_CLIENTI_ARIANNA]}</div>
                                <div className="privileges-ico ico-link" onClick={() => this.changeIcoPriv("P_MAP_CLIENTI_ARIANNA", 0)}>{this.icoPriv[privileges[0].P_MAP_CLIENTI_ARIANNA]}</div>
                            </div>
                            <div className="privileges-list">
                                <div className="privileges-title">Nielsen Targets</div>
                                <div className="privileges-ico ico-no-link">{this.icoPriv[privileges[3].P_MAP_TARGET_NIELSEN]}</div>
                                <div className="privileges-ico ico-link" onClick={() => this.changeIcoPriv("P_MAP_TARGET_NIELSEN", 2)}>{this.icoDeny[privileges[2].P_MAP_TARGET_NIELSEN]}</div>
                                <div className="privileges-ico ico-no-link">{this.icoPriv[privileges[1].P_MAP_TARGET_NIELSEN]}</div>
                                <div className="privileges-ico ico-link" onClick={() => this.changeIcoPriv("P_MAP_TARGET_NIELSEN", 0)}>{this.icoPriv[privileges[0].P_MAP_TARGET_NIELSEN]}</div>
                            </div>
                            <div className="privileges-list">
                                <div className="privileges-title">Arianna Targets</div>
                                <div className="privileges-ico ico-no-link">{this.icoPriv[privileges[3].P_MAP_TARGET_ARIANNA]}</div>
                                <div className="privileges-ico ico-link" onClick={() => this.changeIcoPriv("P_MAP_TARGET_ARIANNA", 2)}>{this.icoDeny[privileges[2].P_MAP_TARGET_ARIANNA]}</div>
                                <div className="privileges-ico ico-no-link">{this.icoPriv[privileges[1].P_MAP_TARGET_ARIANNA]}</div>
                                <div className="privileges-ico ico-link" onClick={() => this.changeIcoPriv("P_MAP_TARGET_ARIANNA", 0)}>{this.icoPriv[privileges[0].P_MAP_TARGET_ARIANNA]}</div>
                            </div>
                            <div className="privileges-list">
                                <div className="privileges-title">Arianna Channels</div>
                                <div className="privileges-ico ico-no-link">{this.icoPriv[privileges[3].P_MAP_CANALI_ARIANNA]}</div>
                                <div className="privileges-ico ico-link" onClick={() => this.changeIcoPriv("P_MAP_CANALI_ARIANNA", 2)}>{this.icoDeny[privileges[2].P_MAP_CANALI_ARIANNA]}</div>
                                <div className="privileges-ico ico-no-link">{this.icoPriv[privileges[1].P_MAP_CANALI_ARIANNA]}</div>
                                <div className="privileges-ico ico-link" onClick={() => this.changeIcoPriv("P_MAP_CANALI_ARIANNA", 0)}>{this.icoPriv[privileges[0].P_MAP_CANALI_ARIANNA]}</div>
                            </div>
                            <div className="privileges-list">
                                <div className="privileges-title">Discovery Channels</div>
                                <div className="privileges-ico ico-no-link">{this.icoPriv[privileges[3].P_MAP_CANALI_DISCOVERY]}</div>
                                <div className="privileges-ico ico-link" onClick={() => this.changeIcoPriv("P_MAP_CANALI_DISCOVERY", 2)}>{this.icoDeny[privileges[2].P_MAP_CANALI_DISCOVERY]}</div>
                                <div className="privileges-ico ico-no-link">{this.icoPriv[privileges[1].P_MAP_CANALI_DISCOVERY]}</div>
                                <div className="privileges-ico ico-link" onClick={() => this.changeIcoPriv("P_MAP_CANALI_DISCOVERY", 0)}>{this.icoPriv[privileges[0].P_MAP_CANALI_DISCOVERY]}</div>
                            </div>

                            
                            <div className="privileges-list title">Advertisers / Products</div>
                            <div className="privileges-list">
                                <div className="privileges-title">Advertisers management</div>
                                <div className="privileges-ico ico-no-link">{this.icoPriv[privileges[3].P_CLIENTI]}</div>
                                <div className="privileges-ico ico-link" onClick={() => this.changeIcoPriv("P_CLIENTI", 2)}>{this.icoDeny[privileges[2].P_CLIENTI]}</div>
                                <div className="privileges-ico ico-no-link">{this.icoPriv[privileges[1].P_CLIENTI]}</div>
                                <div className="privileges-ico ico-link" onClick={() => this.changeIcoPriv("P_CLIENTI", 0)}>{this.icoPriv[privileges[0].P_CLIENTI]}</div>
                            </div>
                            <div className="privileges-list">
                                <div className="privileges-title">Nielsen Products management</div>
                                <div className="privileges-ico ico-no-link">{this.icoPriv[privileges[3].P_PRODOTTI_NIELSEN]}</div>
                                <div className="privileges-ico ico-link" onClick={() => this.changeIcoPriv("P_PRODOTTI_NIELSEN", 2)}>{this.icoDeny[privileges[2].P_PRODOTTI_NIELSEN]}</div>
                                <div className="privileges-ico ico-no-link">{this.icoPriv[privileges[1].P_PRODOTTI_NIELSEN]}</div>
                                <div className="privileges-ico ico-link" onClick={() => this.changeIcoPriv("P_PRODOTTI_NIELSEN", 0)}>{this.icoPriv[privileges[0].P_PRODOTTI_NIELSEN]}</div>
                            </div>
                            <div className="privileges-list">
                                <div className="privileges-title">Arianna Products management</div>
                                <div className="privileges-ico ico-no-link">{this.icoPriv[privileges[3].P_PRODOTTI_ARIANNA]}</div>
                                <div className="privileges-ico ico-link" onClick={() => this.changeIcoPriv("P_PRODOTTI_ARIANNA", 2)}>{this.icoDeny[privileges[2].P_PRODOTTI_ARIANNA]}</div>
                                <div className="privileges-ico ico-no-link">{this.icoPriv[privileges[1].P_PRODOTTI_ARIANNA]}</div>
                                <div className="privileges-ico ico-link" onClick={() => this.changeIcoPriv("P_PRODOTTI_ARIANNA", 0)}>{this.icoPriv[privileges[0].P_PRODOTTI_ARIANNA]}</div>
                            </div>
                            <div className="privileges-list">
                                <div className="privileges-title">Discovery Products management</div>
                                <div className="privileges-ico ico-no-link">{this.icoPriv[privileges[3].P_PRODOTTI_DISCOVERY]}</div>
                                <div className="privileges-ico ico-link" onClick={() => this.changeIcoPriv("P_PRODOTTI_DISCOVERY", 2)}>{this.icoDeny[privileges[2].P_PRODOTTI_DISCOVERY]}</div>
                                <div className="privileges-ico ico-no-link">{this.icoPriv[privileges[1].P_PRODOTTI_DISCOVERY]}</div>
                                <div className="privileges-ico ico-link" onClick={() => this.changeIcoPriv("P_PRODOTTI_DISCOVERY", 0)}>{this.icoPriv[privileges[0].P_PRODOTTI_DISCOVERY]}</div>
                            </div>

                            <div className="privileges-list title">Reports</div>
                            <div className="privileges-list">
                                <div className="privileges-title">Discovery</div>
                                <div className="privileges-ico ico-no-link">{this.icoPriv[privileges[3].P_RPT_DISCOVERY_ANNO_MESE]}</div>
                                <div className="privileges-ico ico-link" onClick={() => this.changeIcoPriv("P_RPT_DISCOVERY_ANNO_MESE", 2)}>{this.icoDeny[privileges[2].P_RPT_DISCOVERY_ANNO_MESE]}</div>
                                <div className="privileges-ico ico-no-link">{this.icoPriv[privileges[1].P_RPT_DISCOVERY_ANNO_MESE]}</div>
                                <div className="privileges-ico ico-link" onClick={() => this.changeIcoPriv("P_RPT_DISCOVERY_ANNO_MESE", 0)}>{this.icoPriv[privileges[0].P_RPT_DISCOVERY_ANNO_MESE]}</div>
                            </div>
                            <div className="privileges-list">
                                <div className="privileges-title">Competitors</div>
                                <div className="privileges-ico ico-no-link">{this.icoPriv[privileges[3].P_RPT_COMPETITORS_ANNO]}</div>
                                <div className="privileges-ico ico-link" onClick={() => this.changeIcoPriv("P_RPT_COMPETITORS_ANNO", 2)}>{this.icoDeny[privileges[2].P_RPT_COMPETITORS_ANNO]}</div>
                                <div className="privileges-ico ico-no-link">{this.icoPriv[privileges[1].P_RPT_COMPETITORS_ANNO]}</div>
                                <div className="privileges-ico ico-link" onClick={() => this.changeIcoPriv("P_RPT_COMPETITORS_ANNO", 0)}>{this.icoPriv[privileges[0].P_RPT_COMPETITORS_ANNO]}</div>
                            </div>

                            <div className="privileges-list title">DasMarianna</div>
                            <div className="privileges-list">
                                <div className="privileges-title">General</div>
                                <div className="privileges-ico ico-no-link">{this.icoPriv[privileges[3].P_POL_COMM]}</div>
                                <div className="privileges-ico ico-link" onClick={() => this.changeIcoPriv("P_POL_COMM", 2)}>{this.icoDeny[privileges[2].P_POL_COMM]}</div>
                                <div className="privileges-ico ico-no-link">{this.icoPriv[privileges[1].P_POL_COMM]}</div>
                                <div className="privileges-ico ico-link" onClick={() => this.changeIcoPriv("P_POL_COMM", 0)}>{this.icoPriv[privileges[0].P_POL_COMM]}</div>
                            </div>
                            <div className="privileges-list">
                                <div className="privileges-title">Import</div>
                                <div className="privileges-ico ico-no-link">{this.icoPriv[privileges[3].P_POL_COMM_IMPORT]}</div>
                                <div className="privileges-ico ico-link" onClick={() => this.changeIcoPriv("P_POL_COMM_IMPORT", 2)}>{this.icoDeny[privileges[2].P_POL_COMM_IMPORT]}</div>
                                <div className="privileges-ico ico-no-link">{this.icoPriv[privileges[1].P_POL_COMM_IMPORT]}</div>
                                <div className="privileges-ico ico-link" onClick={() => this.changeIcoPriv("P_POL_COMM_IMPORT", 0)}>{this.icoPriv[privileges[0].P_POL_COMM_IMPORT]}</div>
                            </div>

                            <div className="privileges-list title">Roobeek</div>
                            <div className="privileges-list">
                                <div className="privileges-title">General</div>
                                <div className="privileges-ico ico-no-link">{this.icoPriv[privileges[3].P_ROOBEEK]}</div>
                                <div className="privileges-ico ico-link" onClick={() => this.changeIcoPriv("P_ROOBEEK", 2)}>{this.icoDeny[privileges[2].P_ROOBEEK]}</div>
                                <div className="privileges-ico ico-no-link">{this.icoPriv[privileges[1].P_ROOBEEK]}</div>
                                <div className="privileges-ico ico-link" onClick={() => this.changeIcoPriv("P_ROOBEEK", 0)}>{this.icoPriv[privileges[0].P_ROOBEEK]}</div>
                            </div>

                            <div className="privileges-list title">Crm Analyzer</div>
                            <div className="privileges-list">
                                <div className="privileges-title">Pipeline Forecast</div>
                                <div className="privileges-ico ico-no-link">{this.icoPriv[privileges[3].P_PIPELINE_FORECAST]}</div>
                                <div className="privileges-ico ico-link" onClick={() => this.changeIcoPriv("P_PIPELINE_FORECAST", 2)}>{this.icoDeny[privileges[2].P_PIPELINE_FORECAST]}</div>
                                <div className="privileges-ico ico-no-link">{this.icoPriv[privileges[1].P_PIPELINE_FORECAST]}</div>
                                <div className="privileges-ico ico-link" onClick={() => this.changeIcoPriv("P_PIPELINE_FORECAST", 0)}>{this.icoPriv[privileges[0].P_PIPELINE_FORECAST]}</div>
                            </div>

                            <div className="privileges-list">
                                <div className="privileges-title">Pipeline Snapshot</div>
                                <div className="privileges-ico ico-no-link">{this.icoPriv[privileges[3].P_PIPELINE_SNAPSHOT]}</div>
                                <div className="privileges-ico ico-link" onClick={() => this.changeIcoPriv("P_PIPELINE_SNAPSHOT", 2)}>{this.icoDeny[privileges[2].P_PIPELINE_SNAPSHOT]}</div>
                                <div className="privileges-ico ico-no-link">{this.icoPriv[privileges[1].P_PIPELINE_SNAPSHOT]}</div>
                                <div className="privileges-ico ico-link" onClick={() => this.changeIcoPriv("P_PIPELINE_SNAPSHOT", 0)}>{this.icoPriv[privileges[0].P_PIPELINE_SNAPSHOT]}</div>
                            </div>
                            <div className="privileges-list">
                                <div className="privileges-title">Tracker Brand Solutions</div>
                                <div className="privileges-ico ico-no-link">{this.icoPriv[privileges[3].P_TRACKER_AVAILAB]}</div>
                                <div className="privileges-ico ico-link" onClick={() => this.changeIcoPriv("P_TRACKER_AVAILAB", 2)}>{this.icoDeny[privileges[2].P_TRACKER_AVAILAB]}</div>
                                <div className="privileges-ico ico-no-link">{this.icoPriv[privileges[1].P_TRACKER_AVAILAB]}</div>
                                <div className="privileges-ico ico-link" onClick={() => this.changeIcoPriv("P_TRACKER_AVAILAB", 0)}>{this.icoPriv[privileges[0].P_TRACKER_AVAILAB]}</div>
                            </div>
                            <div className="privileges-list">
                                <div className="privileges-title">Tracker Pillar</div>
                                <div className="privileges-ico ico-no-link">{this.icoPriv[privileges[3].P_TRACKER_PILLAR]}</div>
                                <div className="privileges-ico ico-link" onClick={() => this.changeIcoPriv("P_TRACKER_PILLAR", 2)}>{this.icoDeny[privileges[2].P_TRACKER_PILLAR]}</div>
                                <div className="privileges-ico ico-no-link">{this.icoPriv[privileges[1].P_TRACKER_PILLAR]}</div>
                                <div className="privileges-ico ico-link" onClick={() => this.changeIcoPriv("P_TRACKER_PILLAR", 0)}>{this.icoPriv[privileges[0].P_TRACKER_PILLAR]}</div>
                            </div>
                            <div className="privileges-list">
                                <div className="privileges-title">Tracker Pillar Daily</div>
                                <div className="privileges-ico ico-no-link">{this.icoPriv[privileges[3].P_TRACKER_PILLAR_DAILY]}</div>
                                <div className="privileges-ico ico-link" onClick={() => this.changeIcoPriv("P_TRACKER_PILLAR_DAILY", 2)}>{this.icoDeny[privileges[2].P_TRACKER_PILLAR_DAILY]}</div>
                                <div className="privileges-ico ico-no-link">{this.icoPriv[privileges[1].P_TRACKER_PILLAR_DAILY]}</div>
                                <div className="privileges-ico ico-link" onClick={() => this.changeIcoPriv("P_TRACKER_PILLAR_DAILY", 0)}>{this.icoPriv[privileges[0].P_TRACKER_PILLAR_DAILY]}</div>
                            </div>
                            <div className="privileges-list">
                                <div className="privileges-title">Overbooking</div>
                                <div className="privileges-ico ico-no-link">{this.icoPriv[privileges[3].P_OVERBOOKING]}</div>
                                <div className="privileges-ico ico-link" onClick={() => this.changeIcoPriv("P_OVERBOOKING", 2)}>{this.icoDeny[privileges[2].P_OVERBOOKING]}</div>
                                <div className="privileges-ico ico-no-link">{this.icoPriv[privileges[1].P_OVERBOOKING]}</div>
                                <div className="privileges-ico ico-link" onClick={() => this.changeIcoPriv("P_OVERBOOKING", 0)}>{this.icoPriv[privileges[0].P_OVERBOOKING]}</div>
                            </div>
                            <div className="privileges-list">
                                <div className="privileges-title">Overbooking (advertising pool)</div>
                                <div className="privileges-ico ico-no-link">{this.icoPriv[privileges[3].P_OVERBOOKING_BACINO]}</div>
                                <div className="privileges-ico ico-link" onClick={() => this.changeIcoPriv("P_OVERBOOKING_BACINO", 2)}>{this.icoDeny[privileges[2].P_OVERBOOKING_BACINO]}</div>
                                <div className="privileges-ico ico-no-link">{this.icoPriv[privileges[1].P_OVERBOOKING_BACINO]}</div>
                                <div className="privileges-ico ico-link" onClick={() => this.changeIcoPriv("P_OVERBOOKING_BACINO", 0)}>{this.icoPriv[privileges[0].P_OVERBOOKING_BACINO]}</div>
                            </div>
                            <div className="privileges-list">
                                <div className="privileges-title">Commission Management</div>
                                <div className="privileges-ico ico-no-link">{this.icoPriv[privileges[3].P_PROVV_COMMISSIONS]}</div>
                                <div className="privileges-ico ico-link" onClick={() => this.changeIcoPriv("P_PROVV_COMMISSIONS", 2)}>{this.icoDeny[privileges[2].P_PROVV_COMMISSIONS]}</div>
                                <div className="privileges-ico ico-no-link">{this.icoPriv[privileges[1].P_PROVV_COMMISSIONS]}</div>
                                <div className="privileges-ico ico-link" onClick={() => this.changeIcoPriv("P_PROVV_COMMISSIONS", 0)}>{this.icoPriv[privileges[0].P_PROVV_COMMISSIONS]}</div>
                            </div>  
                            <div className="privileges-list">
                                <div className="privileges-title">Commission Statements</div>
                                <div className="privileges-ico ico-no-link">{this.icoPriv[privileges[3].P_PROVV_STATEMENTS]}</div>
                                <div className="privileges-ico ico-link" onClick={() => this.changeIcoPriv("P_PROVV_STATEMENTS", 2)}>{this.icoDeny[privileges[2].P_PROVV_STATEMENTS]}</div>
                                <div className="privileges-ico ico-no-link">{this.icoPriv[privileges[1].P_PROVV_STATEMENTS]}</div>
                                <div className="privileges-ico ico-link" onClick={() => this.changeIcoPriv("P_PROVV_STATEMENTS", 0)}>{this.icoPriv[privileges[0].P_PROVV_STATEMENTS]}</div>
                            </div>
                            <div className="privileges-list">
                                <div className="privileges-title">Agenda</div>
                                <div className="privileges-ico ico-no-link">{this.icoPriv[privileges[3].P_AGENDA]}</div>
                                <div className="privileges-ico ico-link" onClick={() => this.changeIcoPriv("P_AGENDA", 2)}>{this.icoDeny[privileges[2].P_AGENDA]}</div>
                                <div className="privileges-ico ico-no-link">{this.icoPriv[privileges[1].P_AGENDA]}</div>
                                <div className="privileges-ico ico-link" onClick={() => this.changeIcoPriv("P_AGENDA", 0)}>{this.icoPriv[privileges[0].P_AGENDA]}</div>
                            </div>                                                       
                        </div>
                    </div>
                </div>

            );
        }
    }
}

export default connect(null,)(privilegeList);
