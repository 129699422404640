import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import asyncComponent from '../../../util/asyncComponent';
import Error404 from '../extraPages/routes/404';

const Options = ({match}) => {
    
    let privileges = {};
    try {
        privileges = JSON.parse(localStorage.getItem('privileges'));
    } catch (e) {
        console.error(e);
    }

    return(
        <React.Fragment>
        {
            ( ( privileges.FLAG_ADMIN === 'Y' ) || ( privileges.P_ANALYSIS_TARGET > 'N' ) ) 
            ?
                <div className="app-wrapper">
                    <Switch>
                        <Redirect exact from={`${match.url}/`} to={`${match.url}/advertisers`}/>
                        { // ripeto questa condizione per i futuri casi in cui altre voci saranno sottoposte ad altri specifici privilegi
                            ( ( privileges.FLAG_ADMIN === 'Y' ) || ( privileges.P_ANALYSIS_TARGET > 'N' ) )
                                ? <Route path={`${match.url}/advertisers/`} component={asyncComponent(() => import('./routes/advertisers'))}/>
                                : null
                        }
                        {
                            ( privileges.FLAG_ADMIN === 'Y' )
                                ? <React.Fragment>
                                    <Route path={`${match.url}/deliveries/`} component={asyncComponent(() => import('./routes/deliveries'))}/>
                                    <Route path={`${match.url}/channels/`} component={asyncComponent(() => import('./routes/channels'))}/>
                                    <Route path={`${match.url}/proposals/`} component={asyncComponent(() => import('./routes/proposals'))}/>
                                    <Route path={`${match.url}/mapping/`} component={asyncComponent(() => import('./routes/mapping'))}/>
                                    <Route path={`${match.url}/kubik-audiences/`} component={asyncComponent(() => import('./routes/kubikAudiences'))}/>
                                  </React.Fragment>
                                : null
                        }
                        <Route component={asyncComponent(() => import('app/routes/extraPages/routes/404'))}/>
                    </Switch>
                </div>
            :
                <Error404/>
        }
        </React.Fragment>
    );
}

export default Options;